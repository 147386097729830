import React, { useEffect, useState } from 'react'
import { AiOutlineRight } from 'react-icons/ai'
import image from '../assets/pexels-pixabay-265072.jpg'
import assets from '../assets/asse';
import { GoArrowLeft } from "react-icons/go";
import { GoArrowRight } from "react-icons/go";
import { Link, useNavigate } from 'react-router-dom'

export default function Blog() {
  
  useEffect (()=>{
    window.scrollTo(0, 0);
   },[])
const navigate = useNavigate()
const[blogPgination, setBlogPagination] = useState([0,6])

    const blog = [
        {
            id:1,
            date:"March 12, 2024" ,
            title:"ASTITVA",
            description:"In a world driven by technology, innovation has the power to transform lives and empower communities. Inodayam Tech Pvt. Ltd",
            image:`${process.env.REACT_APP_BLOGASTITVA}`,
        },
        {
            id:2,
            date:"MARCH 16, 2024" ,
            title:"ADS GRAM",
            description:" Our Ads Gram platform is designed for advertisement and promotion, offering businesses effective tools to reach their target audience and enhance brand visibility in the digital space. To leave lasting impression on people we leveraging 3D Billboards and Hologram",
            image:`${process.env.REACT_APP_BLOGADSGRAM}`,

        },
        {
            id:3,
            date:"MARCH 20, 2024" ,
            title:"MY NETA",
            description:" My Neta is our election campaign platform, providing comprehensive solutions for political candidates to strategize, manage, and execute their election campaigns efficiently. ",
            image:`${process.env.REACT_APP_BLOGMYNETA}`,

        },
        {
            id:4,
            date:"MARCH 24, 2024" ,
            title:"CLUB CARE",
            description:"Club Care serves as a residential societies e-commerce portal and security app, offering essential services and fostering community engagement within residential communities",
            image:`${process.env.REACT_APP_BLOGCLUBCARE}`,

        },
        {
            id:5,
            date:"MARCH 28, 2024" ,
            title:"ASTITVA REALITY",
            description:"Astitva Reality is our online property portal, offering a curated selection of properties for buyers, sellers, and renters, along with expert guidance and support throughout the real estate transaction process ",
            image:`${process.env.REACT_APP_BLOGASTITVAREALITY}`,

        },
        {
            id:6,
            date:"APRIL 1, 2024" ,
            title:"ASTITVA MART",
            description:"Astitva Mart is our online shopping platform, offering a diverse range of high-quality products and services to customers, with a focus on convenience, reliability, and affordability ",
            image:`${process.env.REACT_APP_BLOGASTITVAMART}`,

        },
        {
            id:7,
            date:"APRIL 5, 2024" ,
            title:"DIGI GRAM",
            description:"Digi Gram is dedicated to empowering digital and organic farming practices, providing farmers with tools and resources to optimize crop production and sustainable agricultural practices",
            image:`${process.env.REACT_APP_BLOGDIGIGRAM}`,

        },
        {
            id:8,
            date:"APRIL 9, 2024" ,
            title:"VED-GURUKUL",
            description:"Ved Gurukul is our digital education platform, committed to revolutionizing the education sector by promoting a bag-less education culture and providing digital learning solutions to students and educators of Rural Areas ",
            image:`${process.env.REACT_APP_BLOGVEDGURUKUL}`,

        },
        {
            id:9,
            date:"APRIL 13, 2024" ,
            title:"KRISHI FARM",
            description:"Kisan Farm is an app designed to empower individual farmers, providing them with resources and support to grow crops and businesses effectively, while also fostering community collaboration and knowledge sharing",
            image:`${process.env.REACT_APP_BLOGKRISHIFARM}`,

        },
        {
            id:10,
            date:"APRIL 17, 2024" ,
            title:"FILMY ADDA",
            description:" Filmy Adda is a platform dedicated to providing opportunities for aspiring actors, directors, singers, and other talents in the film industry to showcase their skills and connect with industry professionals",
            image:`${process.env.REACT_APP_BLOGFILMYADDA}`,

        }
    ]
const[data,setData] = useState(blog)
    const[filterData, setFilterData] = useState(blog)
    const handleFilter=(value)=>{
if(value === "all"){
  setFilterData(blog)
}else{
  const DataFilter = data.filter(item=>item.title === value)
  setFilterData(DataFilter)
}
    }



  return (
    // 12/03/2024
<div>
  <div id="page" className="page_wapper hfeed site">
   
    {/*-============== wrapper_full =================*/}
    <div id="wrapper_full" className="content_all_warpper">
     
      {/*--header---*/}

      {/*--header end---*/}
      <div className="page_header_default style_one ">
      <div className="parallax_cover">
        <div className="simpleParallax"><img src={image} alt="bg_image" className="cover-parallax" /></div>
      </div>
      <div className="page_header_content">
        <div className="auto-container">
          <div className="row">
            <div className="col-md-12">
              <div className="banner_title_inner">
                <div className="title_page">
                  Blog
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="breadcrumbs creote">
                <ul className="breadcrumb m-0">
                  <li><Link to="/">Home</Link></li>
                  <li><AiOutlineRight className='mb-1'/></li>
                  <li className="active">Blog</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
      {/*--header---*/}
      {/*--page-CONTENT---*/}
      <div id="content" className="site-content ">
        <div className="container-fluid">
          <div className="row default_row">
            <div id="primary" className="content-area px-4 col-lg-9 col-md-12 col-sm-12 col-xs-12">
              <section className="blog_post_section one_column style_two">
                {/*===============spacing==============*/}
                <div className="pd_top_90" />
                {/*===============spacing==============*/}
                <div className="grid_show_case grid_layout clearfix">
                 
             {filterData.slice(blogPgination[0],blogPgination[1]).map((value,i)=>{
                return(
                    <div className="grid_box _card px-md-2" key={i} onClick={()=>navigate(`/blogpage/${value.title}`)}>
                    <div className="news_box default_style list_view has_images">
                      <div className="image img_hover-1">
                        <img width={750} height={420} src={value.image} className="img-fluid" alt="img" />
                      </div>
                      <div className="content_box">
                        <div className="date">
                          <span className="date_in_number">{value.date}</span>
                        </div>
                        <div className="source">
                          <h2 className="title"><a>{value.title}</a></h2>
                          <p className="short_desc">{value.description}.</p>
                          <Link className="link__go d-flex gap-1 align-items-center" >Read More <GoArrowRight className='fs-6'/></Link>
                        </div>
                        <div className="auhtour_box">
                          <img alt="img" src={process.env.REACT_APP_PRAVINVAIDYA} height={60} width={60} className="img-fluid" />                 
                          <div className="contnet_a">
                            <p>POSTED BY</p>
                            <h4>
                             Pravin Vaidya                    
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
             })}
                
                
                </div>
                <div className="row">
                  <div className="col-lg-12">
  <nav aria-label="Page navigation example">
  <ul className="pagination justify-content-center">
   {filterData.length >= 5? <>  <li className="page-item"><a className="page-link" onClick={()=>setBlogPagination([0,6])}><h6 className='mt-2'>1</h6></a></li>
    <li className="page-item"><a className="page-link"  onClick={()=>setBlogPagination([6,10])}><h6 className='mt-2'>2</h6></a></li>
   </> : ""}
  
    
  </ul>
</nav>

                  </div>
                </div>
                {/*===============spacing==============*/}
                <div className="pd_bottom_70" />
                {/*===============spacing==============*/}
              </section>
            </div>
            <aside id="secondary" className="widget-area all_side_bar px-4 col-lg-3 col-md-12 col-sm-12">
              <div className="side_bar">
                {/*===============spacing==============*/}
                <div className="pd_top_90" />
                {/*===============spacing==============*/}
                <div className="widgets_grid_box">
                  <form role="search" method="get" action="https://themepanthers.com/html/creote-html/index.html/">
                    <div className="wp-block-search__inside-wrapper">
                      <input type="search" name="s" defaultValue placeholder="Key Words here" required disabled/>
                      <i className="fa fa-search" />
                    </div>
                  </form>
                </div>
                <div className="widgets_grid_box">
                  <h2 className="widget-title">About Authour</h2>
                  <div className="about_authour_widget">
                    <h3>Hi! I’m Jacob Leonado</h3>
                    <img src="assets/images/authour-image-wdts.jpg" alt="authourimage" />
                    <p>Obligations of business will frequently occur that pleasure have too repudiated.</p>
                    <a href="#">All My Post</a>
                  </div>
                </div>
                <div className="widgets_grid_box">
                  <h2 className="widget-title">Post Categories</h2>
                  <ul className="wp-block-categories">
                  <li><a href="#" onClick={()=>handleFilter("all")}>All Blogs</a></li>
                    {
                      blog.map((value,i)=>{
                        return(
                          <li key={i}><a href="#" onClick={()=>handleFilter(value.title)}>{value.title}</a></li>
                        )
                      })
                    }
                  </ul>
                </div>
                <div className="widgets_grid_box">
                  <h2 className="widget-title">Recent Posts</h2>
                  <div className="widget_post_box">
                    {
                      blog.slice(5,10).map((value,i)=>{
                        return(
                          <div className="blog_in clearfix image_in"key={i} >
                          <div className="image">
                            <img decoding="async" src={value.image} alt="img" />
                          </div>
                          <div className="content_inner">
                            <p className="post-date"><span className="icon-calendar" />{value.date}</p>
                            <h3><a href="">{value.title}</a></h3>
                          </div>
                        </div>
                        )
                      })
                    }

                  </div>
                </div>
                <div className="widgets_grid_box">
                  <h2 className="widget-title">Tag Clouds</h2>
                  <div className="wp-block-tag-cloud">
                    <a href="#">Appraisal</a>
                    <a href="#">Business</a>
                    <a href="#">Contract</a>
                    <a href="#">Data</a>
                    <a href="#">Efficiency</a>
                    <a href="#">Employment</a>
                    <a href="#">HR Procedures</a>
                    <a href="#">Legal</a>
                    <a href="#">Management</a>
                    <a href="#">Obligations</a>
                  </div>
                </div>
                {/*===============spacing==============*/}
                <div className="pd_bottom_70" />
                {/*===============spacing==============*/}
              </div>
            </aside>
          </div>
        </div>
      </div>
      {/*-newsteller-*/}
      <section className="newsteller style_one bg_dark_1">
        {/*===============spacing==============*/}
        <div className="pd_top_40" />
        {/*===============spacing==============*/}
        <div className="auto-container pd_top_40">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12">
              <div className="content">
                <h2>Join Our Mailing List</h2>
                <p>For receiving our news and updates in your inbox directly. </p>
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="item_scubscribe">
                <div className="input_group">
                  <form className="mc4wp-form" method="post" data-name="Subscibe">
                    <div className="mc4wp-form-fields">
                      <input type="email" name="EMAIL" placeholder="Your email address" required />
                      <input type="submit" defaultValue="Sign up" />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*===============spacing==============*/}
        <div className="pd_bottom_40" />
        {/*===============spacing==============*/}
      </section>
      {/*-newsteller end-*/}
    </div>
    {/*===============PAGE CONTENT END==============*/}
    {/*===============PAGE CONTENT END==============*/}
  </div>
  {/*-============== wrapper_full =================*/}
  
  {/*-==============mobile menu =================*/}
  <div className="crt_mobile_menu">
    <div className="menu-backdrop" />
    <nav className="menu-box">
      <div className="close-btn"><i className="icon-close" /></div>
      <form role="search" method="get" action="#">
        <input type="search" className="search" placeholder="Search..." defaultValue name="s" title="Search" />
        <button type="submit" className="sch_btn"> <i className="icon-search" /></button>
      </form>
      <div className="menu-outer">
        {/*Here Menu Will Come Automatically Via Javascript / Same Menu as in Header*/}
      </div>
    </nav>
  </div>
  {/*-==============mobile menu end =================*/}


  {/* Back to top with progress indicator*/}
  <div className="prgoress_indicator">
    <svg className="progress-circle svg-content" width="100%" height="100%" viewBox="-1 -1 102 102">
      <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
    </svg>
  </div>
</div>


  )
}
