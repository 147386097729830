const AboutusApi = [
    {
        id:1,
        title:"Why Inodayam",
        description:"Inodayam Tech Pvt Ltd stands at the forefront of technological innovation, dedicated to revolutionizing industries and enhancing lives through cutting-edge solutions. With a steadfast commitment to excellence, we leverage the latest advancements in technology to empower businesses, campaigns, and educational institutions alike. Our mission is to drive progress, foster growth, and create a brighter future for all."
    },
    {
        id:2,
        title:"In the Field of Technology",
        description:`Inodayam Tech Pvt Ltd is a beacon of innovation, offering tailored technological solutions to meet the diverse needs of modern businesses. Our expert team of engineers, developers, and strategists harness the power of emerging technologies such as artificial intelligence, machine learning, blockchain, and IoT to drive efficiency, streamline processes, and unlock new opportunities. From custom software development to comprehensive IT consulting, we are dedicated to helping businesses thrive in an increasingly digital world.`
    },
    {
        id:3,
        title:"Campaign for Election",
        description:`When it comes to political campaigns, Inodayam Tech Pvt Ltd is your trusted partner for success. We understand the importance of connecting with voters, building momentum, and delivering impactful messages. Through data-driven strategies, targeted outreach, and innovative campaign management tools, we empower candidates to effectively engage with their constituents, amplify their voices, and secure victory on election day. With our cutting-edge solutions, every campaign becomes a platform for change and progress.`
    },
    {
        id:4,
        title:"Online Schooling",
        description:`Inodayam Tech Pvt Ltd is committed to revolutionizing education through the power of technology. In today's digital age, we recognize the need for flexible, accessible, and engaging learning experiences. That's why we offer comprehensive online schooling solutions designed to meet the evolving needs of students, educators, and institutions. From virtual classrooms and interactive learning platforms to personalized curriculum development and remote teaching tools, we empower learners of all ages to thrive in a dynamic educational landscape. With Inodayam Tech Pvt Ltd, the future of learning is brighter than ever before`
    },
]

export default AboutusApi;