import React, { useEffect, useState } from 'react'
import Navbar from './Navbar'
import '../Style.css';
import image from '../assets/pexels-pixabay-265072.jpg'
import assets from '../assets/asse';
import '../Theme.css';
import AboutusApi from '../API/AboutUsApi'
import { IoLocationOutline } from "react-icons/io5";
import { MdEmail } from "react-icons/md";
import { BiPhoneCall } from "react-icons/bi";
import { HiShare } from "react-icons/hi";
import { BiLogoFacebook } from "react-icons/bi";
import { FaXTwitter } from "react-icons/fa6";
import { BiLogoWhatsapp } from "react-icons/bi";
import { FaTelegram } from "react-icons/fa";
import { AiOutlineInstagram } from "react-icons/ai";
import { AiOutlineRight } from "react-icons/ai";
import { CiPlay1 } from "react-icons/ci";
import { BsArrowRight } from "react-icons/bs";
import image1 from '../assets/icon-img-n-1.png'
import image2 from '../assets/icon-img-n-2.png'
import image3 from '../assets/shape-1.png'
import image4 from '../assets/icon-img-ab-1.jpg'
import image5 from '../assets/icon-img-ab-2.jpg'
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import AboutUsMyNeta from '../Components/AboutUsMyNeta';
import AboutUsAdsgram from '../Components/AboutUsAdsgram';
import AboutUsVedgurukul from '../Components/AboutUsVedgurukul';
export default function Aboutus() {


    useEffect (()=>{
      window.scrollTo(0, 0);
     },[])

const [dataset, setDataSet] = useState()

  const handleFilter = (value) =>{
    const data = AboutusApi.find(item=>item.title === value)
    if(data ){
      setDataSet(data)

    }else{
      setDataSet(AboutusApi[0].description)
     
    }
     
  }
  return (
<div id="page" className="page_wapper  hfeed site">
 
  {/*-============== wrapper_full =================*/}
  <div id="wrapper_full" className="content_all_warpper">

    {/*--header---*/}
   
    {/*--header end---*/}
    {/*--page header---*/}
    <div className="page_header_default style_one ">
      <div className="parallax_cover">
        <img src={image} alt="bg_image" className="cover-parallax" />
      </div>
      <div className="page_header_content">
        <div className="auto-container">
          <div className="row">
            <div className="col-md-12">
              <div className="banner_title_inner">
                <div className="title_page">
                  About Us
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="breadcrumbs creote">
                <ul className="breadcrumb m-auto">
                  <li><Link to="/" href="index.html">Home</Link> </li>
                  <li><AiOutlineRight className='mb-1'/></li>
                  <li className="active">About Us</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/*--page header---*/}
    {/*===============PAGE CONTENT==============*/}
    {/*===============PAGE CONTENT==============*/}
    <div id="content" className="site-content ">
      {/*-about-*/}
      <section className="about-section">
        {/*===============spacing==============*/}
        <div className="pd_top_90" />
        {/*===============spacing==============*/}
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-12 ">
              <div className="about_content position-relative">
                <div className="title_all_box style_one text-left  dark_color">
                  <div className="title_sections">
                    <div className="before_title">We are</div>
                    <h2>Leaders in HR Solution</h2>
                  </div>
                </div>
                {/*===============spacing==============*/}
                <div className="pd_bottom_15" />
                {/*===============spacing==============*/}
                <div className="extra_content image_with_content dark_color">
                  <div className="simple_image">
                    <img src={image} alt="img" />
                    <h2> Since 2024, <br /> Operating in Navi mumbai.</h2>
                  </div>
                </div>
                {/*===============spacing==============*/}
                <div className="pd_bottom_30" />
                {/*===============spacing==============*/}
                <div className="description_box pd_bottom_25">
                  <p>Inodayam Tech Pvt Ltd stands at the forefront of technological innovation, 
                    dedicated to revolutionizing industries and enhancing lives through cutting-edge solutions. 
                    With a steadfast commitment to excellence, we leverage the latest advancements in technology to empower businesses, campaigns, and educational institutions alike.
                     Our mission is to drive progress, foster growth, and create a brighter future for all. </p>
                </div>
                {/*===============spacing==============*/}
                <div className="pd_bottom_25" />
                {/*===============spacing==============*/}
                <div className="row gutter_15px">
                  <div className="col-lg-6 col-md-12">
                    <div className="icon_box_all  style_two">
                      <div className="icon_content  icon_imgs ">
                        <div className="icon">
                          <img src={image1} className="img-fluid svg_image" alt="icon png" />
                        </div>
                        <div className="txt_content">
                          <h3><a href="#" target="_blank" rel="nofollow">Tailored Advice &amp; Support</a>
                          </h3>
                        </div>
                      </div>
                      {/*===============spacing==============*/}
                      <div className="pd_bottom_25" />
                      {/*===============spacing==============*/}
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12  ">
                    <div className="icon_box_all  style_two">
                      <div className="icon_content  icon_imgs ">
                        <div className="icon">
                          <img src={image2} className="img-fluid svg_image" alt="icon png" />
                        </div>
                        <div className="txt_content">
                          <h3><a href="#" target="_blank" rel="nofollow">Flexible Company Policies</a></h3>
                        </div>
                      </div>
                      {/*===============spacing==============*/}
                      <div className="pd_bottom_25" />
                      {/*===============spacing==============*/}
                    </div>
                  </div>
                </div>
                <div className="theme_btn_all color_one pd_bottom_30">
                  <a href="#" target="_blank" rel="nofollow" className="theme-btn five">Contact us<BsArrowRight className="m-1 mb-2 fs-6" /></a>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-12">
              <div className="image_boxes style_two">
                <img src={image3} className="background_image" alt="image" />
                <div className="image one">
                  <img src={process.env.REACT_APP_ABOUTUSIMAGE1} className="img-fluid" alt="image" />
                </div>
                <div className="image two">
                  <img src={process.env.REACT_APP_ABOUTUSIMAGE2} className="img-fluid" alt="image" />
                  <div className="video_box">
                    <a href="#" className="lightbox-image"><CiPlay1 className="fs-3 mb-2" /></a>
                  </div>
                </div>
                <div className="authour_quotes">
                  <i className="icon-quote" />
                  <h6>Making What’s Possible in Human Resource</h6>
                  <p>/ Liam Oliver</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*===============spacing==============*/}
        <div className="pd_bottom_70" />
        {/*===============spacing==============*/}
      </section>
      {/*-about end-*/}
   
   
      {/*-tab--*/}
      <section className="tab-section bg_op_1" style={{backgroundImage: 'url(https://images.pexels.com/photos/434337/pexels-photo-434337.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1)'}}>
        {/*===============spacing==============*/}
        <div className="pd_top_100" />
        {/*===============spacing==============*/}
        <div className="container">
          <div className="row">
            <div className="tabs_all_box  tabs_all_box_start type_one">
              <div className="tab_over_all_box">
                <div className="tabs_header clearfix">
                  <ul className="showcase_tabs_btns nav-pills nav   clearfix">
                    <li className="nav-item" onClick={()=>handleFilter("Why Inodayam")}>
                      <a className="s_tab_btn nav-link active" data-tab="#tabtabone">01. Why Inodayam</a>
                    </li>
                    <li className="nav-item" onClick={()=>handleFilter("In the Field of Technology")}>
                      <a className="s_tab_btn nav-link" data-tab="#tabtabtwo">02. In the Field of Technology</a>
                    </li>
                    <li className="nav-item" onClick={()=>handleFilter("Campaign for Election")}>
                      <a className="s_tab_btn nav-link" data-tab="#tabtabthree">03. Campaign for Election</a>
                    </li>
                    <li className="nav-item" onClick={()=>handleFilter("Online Schooling")}>
                      <a className="s_tab_btn nav-link" data-tab="#tabtabtfour">04. Online Schooling</a>
                    </li>
                  </ul>
                  <div className="toll_free">
                    <a href="tel:180667586677"> <i className=""><BiPhoneCall className="mb-1"/></i>Call For Free
                      Consultation</a>
                  </div>
                </div>
                <div className="s_tab_wrapper">
                  <div className="s_tabs_content">
                    <div className="s_tab fade active-tab show" id="tabtabone">
                      <div className="tab_content one" style={{backgroundImage: 'url(https://img.freepik.com/free-vector/w-colours-pattern-design-template_23-2149844823.jpg?size=626&ext=jpg&ga=GA1.1.22127072.1711692581&semt=ais)'}}>
                        <div className="content_image">
                          <h4>{dataset?dataset.title:AboutusApi[0].title}</h4>
                        
                          <p className='fs-5'>{dataset?dataset.description:AboutusApi[0].description}</p>
                          {/* <a href="#" target="_blank" rel="nofollow" className="rd_more">Read More <i className="icon-right-arrow" /></a> */}
                        </div>
                      </div>
                    </div>
                 
                 
                   
                    <div className="s_tab fade" id="tabtabfive">
                      <div className="tab_content one" style={{backgroundImage: 'url(https://images.pexels.com/photos/434337/pexels-photo-434337.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1)'}}>
                        <div className="content_image">
                          <h6>Why Creote</h6>
                          <h2>Affordable &amp; Flexible</h2>
                          <p>Must explain too you how all this mistaken idea of denouncing pleasures
                            praising pain was born and we will give you complete account of the system
                            the actual teachings of the great explorer.</p>
                          <a href="#" target="_blank" rel="nofollow" className="rd_more">Read More <BsArrowRight className="icon-right-arrow" /></a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*===============spacing==============*/}
        <div className="pd_bottom_90" />
        {/*===============spacing==============*/}
      </section>
      {/*-tab-end-*/}


       {/* -AboutUsVedgurukul-*/}
       <section className="service-icon-section d-flex justify-content-center py-4"  style={{background:"#fbf9f7"}}>
        {/*===============spacing==============*/}
        <div className="pd_top_90" />
        {/*===============spacing==============*/}
        <div className=" col-12 col-lg-11 col-xl-8 px-4">
          <div> <h3 className='text-decoration-underline py-4' style={{color:"#078586"}}>What we do?</h3></div>
          <div>
            <h2>Online education—vedgurukul </h2>
            <p className='fs-4 py-4'>Vedgurukul is a pioneering force in the realm of online education, committed to reshaping the way knowledge is accessed, shared, and applied. With a vision to democratize learning and bridge the gap between quality education and learners worldwide, Vedgurukul stands as a beacon of innovation and excellence in the digital learning landscape.</p>
      


  <AboutUsVedgurukul/>
         
         
          </div>
        </div>
        {/*===============spacing==============*/}
        <div className="pd_top_90" />
        {/*===============spacing==============*/}
      </section>
      {/*-AboutUsVedgurukul end- */}



        {/*My Neta*/}
        <section style={{backgroundColor:"#222222"}}>
            {/*===============spacing==============*/}
        <div className="pd_top_90" />
        {/*===============spacing==============*/}
        
        <div className='container text-white' >
          <h2 className='text-white'>Myneta</h2>
          <p className='py-4'>MyNeta is a groundbreaking platform revolutionizing the intersection of democracy, governance, and citizen engagement. With a mission to foster transparency, accountability, and informed decision-making, MyNeta serves as a comprehensive hub where voters, leaders, and agendas converge. By providing real-time access to information, facilitating meaningful dialogue, and empowering stakeholders, MyNeta empowers citizens to actively participate in the democratic process and shape the future of their communities.</p>
        <AboutUsMyNeta/>
        </div>
         

            {/*===============spacing==============*/}
        <div className="pd_top_90" />
        {/*===============spacing==============*/}
          </section>

        {/*My Neta end*/}


         {/*Ads Gram*/}
         <section className='bg_light_1 d-flex justify-content-center py-4 '>
            {/*===============spacing==============*/}
        <div className="pd_top_90" />
        {/*===============spacing==============*/}
        
        <div className='col-12 col-lg-8 col-xl-8 px-4 '>
          <h2>Ads Gram</h2>
          <p className='py-4'>
            AdsGram stands as a pioneering adserver, revolutionizing the landscape of advertising by offering a dynamic platform where advertisers can seamlessly post their ads according to client requirements. With a mission to bridge the gap between businesses and their target audience, AdsGram provides a versatile solution for delivering impactful advertisements across a variety of mediums, including LED billboards, social media platforms, holograms, large-scale flex displays, and more. By harnessing the power of innovative technology and strategic placement, AdsGram empowers advertisers to maximize their reach, engagement, and impact, ensuring that their message resonates with audiences far and wide.
          </p>
        <AboutUsAdsgram/>
        </div>
         

            {/*===============spacing==============*/}
        <div className="pd_top_90" />
        {/*===============spacing==============*/}
          </section>

        {/*Ads Gram end*/}

      {/*-team-*/}
      <section className="team-section">
        {/*===============spacing==============*/}
        <div className="pd_top_80" />
        {/*===============spacing==============*/}
        <div className="container">
          <div className="row align-items-end">
            <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div className="title_all_box style_one  dark_color">
                <div className="title_sections">
                  <div className="before_title"> Dedicated Team</div>
                  <h2>Professional Individuals</h2>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div className="description_box">
                <p>Our power of choice is untrammelled and when nothing prevents being able to do what we
                  like best every pleasure.</p>
                {/*===============spacing==============*/}
                <div className="mr_bottom_20" />
                {/*===============spacing==============*/}
              </div>
            </div>
            {/*===============spacing==============*/}
            <div className="mr_bottom_30" />
            {/*===============spacing==============*/}
          </div>
          <div className="row pd_top_30">
            <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 px-md-4 px-lg-4 px-xl-4">
              <div className="team_box style_one">
                <div className="team_box_outer">
                  <div className="member_image">
                    <img src={image} alt="team image" />
                  </div>
                  <div className="about_member">
                    <div className="share_media">
                      <ul className="first">
                        <li className="text">Share</li>
                        <li><i className=""><HiShare/></i></li>
                      </ul>
                      <ul>
                        <li className="shar_alt"><i className=""><HiShare className="mb-1"/></i></li>
                        <li><a href="#"> <i className=""><BiLogoFacebook className='text-dark fs-5'/> </i></a></li>
                        <li><a href="#"> <i className=""><FaXTwitter className='text-dark fs-5' /> </i></a></li>
                        <li><a href="#"> <i className=""><BiLogoWhatsapp className='text-dark fs-5' /> </i></a></li>
                        <li><a href="#"> <i className=""><AiOutlineInstagram className='text-dark fs-5'/> </i></a></li>
                      </ul>
                    </div>
                    <div className="authour_details">
                      <span>Director </span>
                      <h6>Amelia Margaret</h6>
                      <div className="button_view">
                        <a href="#" target="_blank" rel="nofollow" className="theme-btn one"> View Profile
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/*===============spacing==============*/}
              <div className="mr_bottom_30" />
              {/*===============spacing==============*/}
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 px-md-4 px-lg-4 px-xl-4">
              <div className="team_box style_one">
                <div className="team_box_outer">
                  <div className="member_image">
                    <img src={image} alt="team image" />
                  </div>
                  <div className="about_member">
                    <div className="share_media">
                      <ul className="first">
                        <li className="text">Share</li>
                        <li><i className=""><HiShare/></i></li>
                      </ul>
                      <ul>
                        <li className="shar_alt"><i className=""><HiShare className="mb-1"/></i></li>
                        <li><a href="#"><i className=""><BiLogoFacebook className='text-dark fs-5'/></i> </a></li>
                        <li><a href="#"> <i className=""><FaXTwitter className='text-dark fs-5' /> </i></a></li>
                        <li><a href="#"> <i className=""><BiLogoWhatsapp className='text-dark fs-5' /> </i></a></li>
                        <li><a href="#"> <i className=""><AiOutlineInstagram className='text-dark fs-5'/> </i></a></li>
                      </ul>
                    </div>
                    <div className="authour_details">
                      <span>Team Leader </span>
                      <h6>Andrew Cameron</h6>
                      <div className="button_view">
                        <a href="#" target="_blank" rel="nofollow" className="theme-btn one"> View Profile
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/*===============spacing==============*/}
              <div className="mr_bottom_30" />
              {/*===============spacing==============*/}
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 px-md-4 px-lg-4 px-xl-4">
              <div className="team_box style_one">
                <div className="team_box_outer">
                  <div className="member_image">
                    <img src={image} alt="team image" />
                  </div>
                  <div className="about_member">
                    <div className="share_media">
                      <ul className="first">
                        <li className="text">Share</li>
                        <li><i className=""><HiShare/></i></li>
                      </ul>
                      <ul>
                        <li className="shar_alt"><i className=""><HiShare className="mb-1"/></i></li>
                        <li><a href="#"> <i className=""><BiLogoFacebook className='text-dark fs-5'/></i> </a></li>
                        <li><a href="#"> <i className=""><FaXTwitter className='text-dark fs-5' /> </i></a></li>
                        <li><a href="#"> <i className=""><BiLogoWhatsapp className='text-dark fs-5' /> </i></a></li>
                        <li><a href="#"> <i className=""><AiOutlineInstagram className='text-dark fs-5'/> </i></a></li>
                      </ul>
                    </div>
                    <div className="authour_details">
                      <span>Manager </span>
                      <h6>Sofia Charlotte</h6>
                      <div className="button_view">
                        <a href="#" target="_blank" rel="nofollow" className="theme-btn one"> View Profile
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/*===============spacing==============*/}
              <div className="mr_bottom_30" />
              {/*===============spacing==============*/}
            </div>
          </div>
        </div>
        {/*===============spacing==============*/}
        <div className="pd_bottom_60" />
        {/*===============spacing==============*/}
      </section>
      {/*-team-end-*/}
      {/*-newsteller-*/}
      <section className="newsteller style_one bg_dark_1">
        {/*===============spacing==============*/}
        <div className="pd_top_40" />
        {/*===============spacing==============*/}
        <div className="auto-container pd_top_40">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12">
              <div className="content">
                <h2>Join Our Mailing List</h2>
                <p>For receiving our news and updates in your inbox directly. </p>
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="item_scubscribe">
                <div className="input_group">
                  <form className="mc4wp-form" method="post" data-name="Subscibe">
                    <div className="mc4wp-form-fields">
                      <input type="email" name="EMAIL" placeholder="Your email address" required />
                      <input type="submit" defaultValue="Sign up" />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*===============spacing==============*/}
        <div className="pd_bottom_40" />
        {/*===============spacing==============*/}
      </section>
      {/*-newsteller end-*/}
    </div>
    {/*===============PAGE CONTENT END==============*/}
    {/*===============PAGE CONTENT END==============*/}
  </div>
  {/*-============== wrapper_full =================*/}
 
  {/*-==============mobile menu =================*/}
  <div className="crt_mobile_menu">
    <div className="menu-backdrop" />
    <nav className="menu-box">
      <div className="close-btn"><i className="icon-close" /></div>
      <form role="search" method="get" action="#">
        <input type="search" className="search" placeholder="Search..." defaultValue name="s" title="Search" />
        <button type="submit" className="sch_btn"> <i className="icon-search" /></button>
      </form>
      <div className="menu-outer">
        {/*Here Menu Will Come Automatically Via Javascript / Same Menu as in Header*/}
      </div>
    </nav>
  </div>
  {/*-==============mobile menu end =================*/}

 
  
</div>

  )
}
