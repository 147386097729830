import React from 'react'
import assets from '../assets/asse'

export default function AboutUsMyNeta() {
  return (
<>
      <div className='aboutIntegrated d-flex flex-wrap border-bottom border-white pd_bottom_60 ' >

        <div className='col-12 col-lg-6 col-xl-6 px-4 d-flex flex-wrap justify-content-center'>
     <div className='mb-4 mb-md-4 mb-lg-0 mb-xl-0'>
     <img src={process.env.REACT_APP_ABOUTUSIMAGEMYNETAINTEGRATEDMANAGEMENT1}/>
     </div>
          
          <div >
          <img width='400px' src={process.env.REACT_APP_ABOUTUSIMAGEMYNETAINTEGRATEDMANAGEMENT2}/>
          </div>
        </div>
     
        <div className='col-12 col-lg-6 col-xl-6 px-4'>
<div className='pb-4'>
  <h2 className='text-white pb-3'> Integrated Management of Voters and Leaders:</h2>
  <p>MyNeta offers a seamless platform for managing voters and leaders, bringing them together in a dynamic digital ecosystem. Through intuitive interfaces and robust databases, voters can explore the profiles, track records, and platforms of political leaders, enabling informed decision-making during elections. Simultaneously, leaders can connect with their constituents, share their vision, and solicit feedback, fostering a deeper understanding and connection between elected officials and the communities they serve.</p>
</div>
          <div className='ms-3'>
          <img width='340px' src={process.env.REACT_APP_ABOUTUSIMAGEMYNETAINTEGRATEDMANAGEMENT3}/>
          </div>

        </div>

      </div>
        {/*spacing */}
<div className='pd_top_60'></div>
{/*spacing */}


      <div className='aboutIntegrated d-flex flex-wrap border-bottom border-white pd_bottom_60  ' >

        <div className='col-12 col-lg-6 col-xl-6 px-4 d-flex flex-wrap justify-content-center'>
     <div className='mb-4 mb-md-4 mb-lg-0 mb-xl-0'>
     <img src={process.env.REACT_APP_ABOUTUSIMAGEMYNETAAGENDASOLVING1}/>
     </div>
          
          <div>
          <img width='400px' src={process.env.REACT_APP_ABOUTUSIMAGEMYNETAAGENDASOLVING2}/>
          </div>
        </div>
     
        <div className='col-12 col-lg-6 col-xl-6 px-4'>
<div className='pb-4'>
  <h2 className='text-white pb-3'> Agenda Solving and Demographics Issues:</h2>
  <p>MyNeta goes beyond mere information dissemination, actively facilitating dialogue and collaboration to address pressing agendas and demographic issues. Through innovative features such as discussion forums, town hall meetings, and collaborative problem-solving tools, MyNeta empowers citizens and leaders to work together to identify challenges, propose solutions, and drive positive change. By harnessing the collective wisdom and expertise of diverse stakeholders, MyNeta catalyzes meaningful progress on issues ranging from healthcare and education to infrastructure and environmental sustainability.</p>
</div>
          <div className='ms-3'>
          <img width='340px' src={process.env.REACT_APP_ABOUTUSIMAGEMYNETAAGENDASOLVING3}/>
          </div>

        </div>

      </div>


      {/*spacing */}
<div className='pd_top_60'></div>
{/*spacing */}

<div className='aboutIntegrated d-flex flex-wrap border-bottom border-white pd_bottom_60  ' >

<div className='col-12 col-lg-6 col-xl-6 px-4 d-flex flex-wrap justify-content-center'>
<div className='mb-4 mb-md-4 mb-lg-0 mb-xl-0'>
<img src={process.env.REACT_APP_ABOUTUSIMAGEMYNETALIVETELECASTS1}/>
</div>
  
  <div>
  <img width='400px' src={process.env.REACT_APP_ABOUTUSIMAGEMYNETALIVETELECASTS2}/>
  </div>
</div>

<div className='col-12 col-lg-6 col-xl-6 px-4'>
<div className='pb-4'>
<h2 className='text-white pb-3'>  Live Telecasts and Face-to-Face Interactions:</h2>
<p>MyNeta leverages the power of live telecasts and face-to-face interactions to foster authentic dialogue and accountability between voters and leaders. Through virtual town halls, televised debates, and interactive Q&A sessions, citizens have the opportunity to directly engage with political leaders, posing questions, voicing concerns, and holding them accountable for their actions and promises. By facilitating transparent and accessible communication channels, MyNeta strengthens the bond of trust between elected officials and the electorate, ensuring that leaders remain responsive and accountable to the needs of their constituents.</p>
</div>
  <div className='ms-3'>
  <img width='340px' src={process.env.REACT_APP_ABOUTUSIMAGEMYNETALIVETELECASTS3}/>
  </div>

</div>

</div>


{/*spacing */}
<div className='pd_top_60'></div>
{/*spacing */}


<div className='aboutIntegrated d-flex flex-wrap pd_bottom_60 ' >

<div className='col-12 col-lg-6 col-xl-6 px-4 d-flex flex-wrap justify-content-center'>
<div className='mb-4 mb-md-4 mb-lg-0 mb-xl-0'>
<img src={process.env.REACT_APP_ABOUTUSIMAGEMYNETAEMPOWERINGDEMOCRACY1}/>
</div>
  
  <div>
  <img width='400px' src={process.env.REACT_APP_ABOUTUSIMAGEMYNETAEMPOWERINGDEMOCRACY2}/>
  </div>
</div>

<div className='col-12 col-lg-6 col-xl-6 px-4'>
<div className='pb-4'>
<h2 className='text-white pb-3'>   Empowering Democracy, One Citizen at a Time:</h2>
<p>At its core, MyNeta is driven by a commitment to empower democracy at every level, from local communities to national governance. By democratizing access to information, facilitating meaningful dialogue, and fostering transparency and accountability, MyNeta empowers citizens to become active participants in the democratic process, driving positive change and shaping the future of their nation. With MyNeta, democracy becomes not just a system of governance, but a living, breathing dialogue between citizens and their elected representatives, united in their shared pursuit of a better tomorrow</p>
</div>
  <div className='ms-3'>
  <img width='340px' src={process.env.REACT_APP_ABOUTUSIMAGEMYNETAEMPOWERINGDEMOCRACY3}/>
  </div>

</div>

</div>


</>
  )
}
