import React from 'react'
import { BsArrowRight } from "react-icons/bs";
import assets from '../assets/asse';
export default function AboutUsVedgurukul() {
  return (
   
    <div className='AboutUsVedgurukulMain '>

        <div className='sticktotop d-flex flex-wrap  rounded-4 px-4 '>
        <div className='sticktotopUpper col-12 col-lg-6 col-xl-6 pe-0 pe-lg-4 pe-xl-4'>
        <p className='fs-4 fw-bold text-decoration-underline'>Online Education Redefined:</p>
        <p className='fs-5 py-0 py-lg-4 py-xl-4'>
      Vedgurukul leverages cutting-edge technology and pedagogical expertise to deliver a comprehensive online learning experience like no other. Through intuitive platforms, interactive content, and personalized learning pathways, we empower students to unlock their full potential, regardless of geographical constraints or traditional educational barriers.
      </p>
      <button className='d-flex align-items-center gap-1'>Read More <BsArrowRight/></button>
        </div>

        <div className='sticktotopLower col-12 col-lg-6 col-xl-6 pt-3 pt-lg-0 pt-xl-0'>
        <img src={process.env.REACT_APP_ABOUTUSVEDGURUKULONLINEEDU}/>
            
        </div>
        
        </div>


     {/*===============spacing==============*/}
     <div className="pd_top_40" />
        {/*===============spacing==============*/}



        <div className='sticktotop  d-flex flex-wrap rounded-4 px-4'>
        <div className='col-12 col-lg-6 col-xl-6 pe-4'>
        <p className='fs-4 fw-bold text-decoration-underline'>Holistic Approach to Learning:</p>
        <p className='fs-5 py-0 py-lg-4 py-xl-4'>
        At Vedgurukul, we understand that education extends beyond textbooks and classrooms. That's why our curriculum is carefully curated to foster holistic development, encompassing academic excellence, critical thinking, creativity, and emotional intelligence. Through a diverse array of courses, workshops, and extracurricular activities, we nurture well-rounded individuals equipped to thrive in an ever-changing world.    
      </p>
      <button className='d-flex align-items-center gap-1'>Read More <BsArrowRight/></button>
        </div>

        <div className='col-12 col-lg-6 col-xl-6 pt-3 pt-lg-0 pt-xl-0'>
            <img src={process.env.REACT_APP_ABOUTUSIMAGEVEDGURUKULHOLISTICAPPROACH}/>
        </div>
        </div>

        

 {/*===============spacing==============*/}
 <div className="pd_top_40" />
        {/*===============spacing==============*/}


        <div className='sticktotop  d-flex flex-wrap rounded-4 px-4'>
        <div className='col-12 col-lg-6 col-xl-6 pe-4'>
        <p className='fs-4 fw-bold text-decoration-underline'>Expert Faculty and Mentorship:</p>
        <p className='fs-5 py-0 py-lg-4 py-xl-4'>
        Our team of experienced educators and industry professionals serve as mentors, guides, and role models, inspiring students to reach new heights of academic achievement and personal growth. With their expertise and support, learners receive invaluable insights, constructive feedback, and mentorship tailored to their individual needs and aspirations.   
      </p>
      <button className='d-flex align-items-center gap-1'>Read More <BsArrowRight/></button>
        </div>

        <div className='col-12 col-lg-6 col-xl-6 pt-3 pt-lg-0 pt-xl-0'>
            <img src={process.env.REACT_APP_ABOUTUSVEDGURUKULEXPERTFACULTY}/>
        </div>
        </div>


        

 {/*===============spacing==============*/}
 <div className="pd_top_40" />
        {/*===============spacing==============*/}


        <div className='sticktotop  d-flex flex-wrap rounded-4 px-4'>
        <div className='col-12 col-lg-6 col-xl-6 pe-4'>
        <p className='fs-4 fw-bold text-decoration-underline'>Accessible and Inclusive Learning:</p>
        <p className='fs-5 py-0 py-lg-4 py-xl-4'>
        Vedgurukul believes that education should be accessible to all, regardless of background or circumstance. That's why we strive to make our online learning platforms inclusive, accommodating diverse learning styles, languages, and abilities. Whether you're a student in a bustling metropolis or a remote village, Vedgurukul ensures that quality education is always within reach.     
      </p>
      <button className='d-flex align-items-center gap-1'>Read More <BsArrowRight/></button>
        </div>
        <div className='col-12 col-lg-6 col-xl-6 pt-3 pt-lg-0 pt-xl-0'>
            <img src={process.env.REACT_APP_ABOUTUSIMAGEVEDGURUKULACCESSIBLEANDINCLUSIVE}/>
        </div>
        </div>



        
 {/*===============spacing==============*/}
 <div className="pd_top_40" />
        {/*===============spacing==============*/}



        <div className='sticktotop  d-flex flex-wrap rounded-4 px-4'>
        <div className='col-12 col-lg-6 col-xl-6 pe-4'>
        <p className='fs-4 fw-bold text-decoration-underline'>Driving Innovation and Impact:</p>
        <p className='fs-5 py-0 py-lg-4 py-xl-4'>
        As champions of innovation, Vedgurukul continuously explores emerging technologies and pedagogical approaches to enhance the learning experience and drive meaningful outcomes. Through research, experimentation, and collaboration, we remain at the forefront of educational innovation, pioneering new solutions to address the evolving needs of learners and educators worldwide. 
      </p>
      <button className='d-flex align-items-center gap-1'>Read More <BsArrowRight/></button>
        </div>
        <div className='col-12 col-lg-6 col-xl-6 pt-3 pt-lg-0 pt-xl-0'>
            <img src={process.env.REACT_APP_ABOUTUSVEDGURUKULDRIVINGINNOVATION}/>
        </div>
        </div>



    {/*===============spacing==============*/}
    <div className="pd_top_40" />
        {/*===============spacing==============*/}



        <div className='sticktotop  d-flex flex-wrap rounded-4 px-4'>
        <div className='col-12 col-lg-6 col-xl-6 py-3 mb-0 pe-4'>
        <p className='fs-4 fw-bold text-decoration-underline'>Join the Vedgurukul Community:</p>
        <p className='fs-5 py-0 py-lg-4 py-xl-4'>
        Join us on our journey to transform education and empower the next generation of learners. Whether you're a student seeking knowledge, an educator passionate about teaching, or a parent invested in your child's future, Vedgurukul welcomes you to explore, learn, and grow with us. Together, let's redefine the possibilities of online education and shape a brighter future for generations to come.

      </p>
      <button className='d-flex align-items-center gap-1'>Read More <BsArrowRight/></button>
        </div>

        <div className='col-12 col-lg-6 col-xl-6 py-0 pt-0 pt-lg-3 pt-xl-3'>
            <img src={process.env.REACT_APP_ABOUTUSIMAGEVEDGURUKULJOINTHEVEDGURUKUL}/>
        </div>
        </div>


        

    </div>
  
  )
}
