
import $ from 'jquery';
$(document).ready(function(){
    $(window).bind('scroll', function() {
        var navHeight = $('.navbar').height();
        if ($(window).scrollTop() > navHeight + 88) {
            $('.navbar').addClass('navSticky');
            // $('.navbar').addClass('backgroundTrans');
          
        }
        else {
            $('.navbar').removeClass('navSticky');
            // $('.navbar').removeClass('backgroundTrans');
           
        }
    });
});
