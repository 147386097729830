import React, { useEffect } from 'react'
import '../Style.css';
import '../Theme.css';
import image from '../assets/pexels-pixabay-265072.jpg'
import { IoLocationOutline } from "react-icons/io5";
import { MdEmail } from "react-icons/md";
import { BiLogoWhatsapp } from "react-icons/bi";
import { BiLogoFacebook } from "react-icons/bi";
import { FaXTwitter } from "react-icons/fa6";
import { AiOutlineInstagram } from "react-icons/ai";
import { AiOutlineRight } from "react-icons/ai";
import { AiOutlineClockCircle } from "react-icons/ai";

import { Link } from 'react-router-dom';
export default function Contact() {
  
  useEffect (()=>{
    window.scrollTo(0, 0);
   },[])
  return (
<div id="page" className="page_wapper allmain hfeed site">

  {/*-============== wrapper_full =================*/}
  <div id="wrapper_full" className="content_all_warpper">
 
  
    {/*--header---*/}

    {/*--header end---*/}
    <div className="page_header_default style_one ">
      <div className="parallax_cover">
        <div className="simpleParallax"><img src={image} alt="bg_image" className="cover-parallax" /></div>
      </div>
      <div className="page_header_content">
        <div className="auto-container">
          <div className="row">
            <div className="col-md-12">
              <div className="banner_title_inner">
                <div className="title_page">
                  Contact
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="breadcrumbs creote">
                <ul className="breadcrumb m-0">
                  <li><Link to="/" href="index.html">Home</Link></li>
                  <li><AiOutlineRight className='mb-1'/></li>
                  <li className="active">Contact</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/*--header---*/}
    {/*--page-CONTENT---*/}
    <div id="content" className="site-content ">
      <section className="contact-section">
        {/*===============spacing==============*/}
        <div className="pd_top_90" />
        {/*===============spacing==============*/}
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-6 col-lg-6 mb-5 mb-lg-5 mb-xl-0">
              <div className="contact_form_box_all type_one">
                <div className="contact_form_box_inner">
                  <div className="contact_form_shortcode">
                    <form id="contact-form" method="post" action="https://themepanthers.com/html/creote-html/contact.php" role="form">
                      <div className="messages" />
                      <div className="controls">
                        <div className="row">
                          <div className="col-sm-12">
                            <div className="form-group">
                              <label> Your Name<br /></label>
                              <input type="text" name="name" placeholder="Your Name *" required="required" data-error="Enter Your Name" />
                              <div className="help-block with-errors" />
                            </div>
                          </div>
                          <div className="col-sm-12">
                            <div className="form-group">
                              <label> Your Email<br /></label>
                              <input type="text" name="email" required="required" placeholder="Email *" data-error="Enter Your Email Id" />
                              <div className="help-block with-errors" />
                            </div>
                          </div>
                          <div className="col-sm-12">
                            <div className="form-group">
                              <label> Your Subject<br /></label>
                              <input type="text" name="subject" required="required" placeholder=" Subject  (Optional)" />
                            </div>
                          </div>
                          <div className="col-sm-12">
                            <div className="form-group">
                              <label> Your Message<br /></label>
                              <textarea name="message" placeholder="Additional Information... (Optional) " rows={3} required="required" data-error="Please, leave us a message." defaultValue={""} />
                              <div className="help-block with-errors" />
                            </div>
                          </div>
                          <div className="col-sm-12">
                            <div className="form-group mg_top apbtn">
                              <button className="theme_btn" type="submit">Appointment</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 pd_left_30">
              <div className="title_all_box style_one dark_color">
                <div className="title_sections left">
                  <div className="before_title">Contact Info to</div>
                  <h2>Reach Our Expert Team</h2>
                  <p>Send a message through given form, If your enquiry is time sensitive please use below
                    contact details.</p>
                </div>
              </div>
              <div className="contact_box_content style_one">
                <div className="contact_box_inner icon_yes">
                  <div className="icon_bx">
                    <span className=" "><IoLocationOutline/></span>
                  </div>
                  <div className="contnet">
                    <h3> Post Address </h3>
                    <p>
                      280 Granite Run Drive Suite #200 Lancaster, PA 1760
                    </p>
                  </div>
                </div>
              </div>
              {/*===============spacing==============*/}
              <div className="pd_bottom_15" />
              {/*===============spacing==============*/}
              <div className="contact_box_content style_one">
                <div className="contact_box_inner icon_yes">
                  <div className="icon_bx">
                    <span className="icon-phone-call"><MdEmail/></span>
                  </div>
                  <div className="contnet">
                    <h3> General Enquires </h3>
                    <p>
                      Phone: +98 060 712 34 &amp; Email: sendmail@qetus.com
                    </p>
                  </div>
                </div>
              </div>
              {/*===============spacing==============*/}
              <div className="pd_bottom_15" />
              {/*===============spacing==============*/}
              <div className="contact_box_content style_one">
                <div className="contact_box_inner icon_yes">
                  <div className="icon_bx">
                    <span ><AiOutlineClockCircle/></span>
                  </div>
                  <div className="contnet">
                    <h3> Operation Hours </h3>
                    <p>
                      Mon-Satday: 09.00 to 07.00 (Sunday: Closed)
                    </p>
                  </div>
                </div>
              </div>
              {/*===============spacing==============*/}
              <div className="pd_bottom_40" />
              {/*===============spacing==============*/}
              <div className="social_media_v_one style_two">
                <ul>
                  <li>
                    <a href="#"> <span className="fs-6"><BiLogoFacebook/></span>
                      <small>facebook</small>
                    </a>
                  </li>
                  <li>
                    <a href="#"> <span className=" fs-6"><FaXTwitter/></span>
                      <small>twitter</small>
                    </a>
                  </li>
                  <li>
                    <a href="#"> <span className=" fs-6"><BiLogoWhatsapp/></span>
                      <small>whatsapp</small>
                    </a>
                  </li>
                  <li>
                    <a href="#"> <span className="fs-6 "><AiOutlineInstagram/></span>
                      <small>instagram</small>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        {/*===============spacing==============*/}
        <div className="pd_top_70" />
        {/*===============spacing==============*/}
      </section>
      <section className="contact-map-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <section className="map-section">
                {/*Map Outer*/}
                <div className="map-outer">
              <iframe width={600} height={450} style={{border: 0}} loading="lazy" allowFullScreen referrerPolicy="no-referrer-when-downgrade" src="">
</iframe>

                </div>
              </section>
            </div>
          </div>
        </div>
        {/*===============spacing==============*/}
        <div className="pd_top_90" />
        {/*===============spacing==============*/}
      </section>
      {/*-newsteller-*/}
      <section className="newsteller style_one bg_dark_1">
        {/*===============spacing==============*/}
        <div className="pd_top_40" />
        {/*===============spacing==============*/}
        <div className="auto-container pd_top_40">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12">
              <div className="content">
                <h2>Join Our Mailing List</h2>
                <p>For receiving our news and updates in your inbox directly. </p>
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="item_scubscribe">
                <div className="input_group">
                  <form className="mc4wp-form" method="post" data-name="Subscibe">
                    <div className="mc4wp-form-fields">
                      <input type="email" name="EMAIL" placeholder="Your email address" required />
                      <input type="submit" defaultValue="Sign up" />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*===============spacing==============*/}
        <div className="pd_bottom_40" />
        {/*===============spacing==============*/}
      </section>
      {/*-newsteller end-*/}
    </div>
  </div>
 
  {/*-==============mobile menu =================*/}
  <div className="crt_mobile_menu">
    <div className="menu-backdrop" />
    <nav className="menu-box">
      <div className="close-btn"><i className="icon-close" /></div>
      <form role="search" method="get" action="#">
        <input type="search" className="search" placeholder="Search..." defaultValue name="s" title="Search" />
        <button type="submit" className="sch_btn"> <i className="icon-search" /></button>
      </form>
      <div className="menu-outer">
        {/*Here Menu Will Come Automatically Via Javascript / Same Menu as in Header*/}
      </div>
    </nav>
  </div>
  {/*-==============mobile menu end =================*/}
  {/*-==============search popup =================*/}
  <div id="search-popup" className="search-popup">
    <div className="close-search"><i className="fa fa-times" /></div>
    <div className="popup-inner">
      <div className="overlay-layer" />
      <div className="search-form">
        <fieldset>
          <form role="search" method="get" action="#">
            <input type="search" className="search" placeholder="Search..." defaultValue name="s" title="Search" />
            <button type="submit" className="sch_btn"> <i className="icon-search" /></button>
          </form>
        </fieldset>
      </div>
    </div>
  </div>
  {/*-==============search popup end =================*/}
  {/*-==============modal popup =================*/}
  <div className="modal_popup one">
    <div className="modal-popup-inner">
      <div className="close-modal"><i className="fa fa-times" /></div>
      <div className="modal_box">
        <div className="row">
          <div className="col-lg-5 col-md-12 form_inner">
            <div className="form_content">
              <form className="contact-form" method="post" action="https://themepanthers.com/html/creote-html/sendemail.php">
                <p>
                  <label> Your name<br />
                    <input type="text" name="name" defaultValue size={40} aria-required="true" aria-invalid="false" placeholder="Enter Your Name" />
                    <br />
                    <i className="fa fa-user" /><br />
                  </label>
                </p>
                <p><label> Your email<br />
                    <input type="email" name="email" defaultValue size={40} aria-required="true" aria-invalid="false" placeholder="Enter Your Email" />
                    <br />
                    <i className="fa fa-envelope" /><br />
                  </label>
                </p>
                <p>
                  <label> Subject<br />
                    <input type="text" name="subject" defaultValue size={40} aria-required="true" aria-invalid="false" placeholder="Enter Your Subject" />
                    <br />
                    <i className="fa fa-folder" /><br />
                  </label>
                </p>
                <p>
                  <label> Your message (optional)<br />
                    <textarea name="message" cols={40} rows={10} className="wpcf7-form-control wpcf7-textarea" aria-invalid="false" placeholder="Enter Your Message" defaultValue={""} />
                    <br />
                    <i className="fa fa-comments" /><br />
                  </label>
                </p>
                <p><input type="submit" defaultValue="Submit" /></p>
              </form>
            </div>
          </div>
          <div className="col-lg-7 col-md-12 about_company_inner">
            <div className="abt_content">
              <div className="logo">
                <img src="assets/images/logo-default.png" alt="img" className="company_logo_modal" />
              </div>
              <div className="text">
                <p> The great explorer of the truth, the master-builder of human happiness no one rejects
                  dislikes avoids pleasure itself because it is pleasure but because know who do not those
                  how to pursue pleasures rationally encounter consequences that are extremely painful
                  desires to obtain.</p>
                <a href="#">Read More</a>
              </div>
              <div className="post_contet_modal">
                <h2> Latest News</h2>
                <div className="post_enable">
                  <div className="modal_post_grid">
                    <a href="blog-single.html">
                      <img width={852} height={812} src="assets/images/blog/blog-image-9.jpg" className="main_img wp-post-image" alt="img" />
                    </a>
                  </div>
                  <div className="modal_post_grid">
                    <a href="blog-single.html">
                      <img width={852} height={812} src="assets/images/blog/blog-image-8.jpg" className="main_img wp-post-image" alt="img" />
                    </a>
                  </div>
                  <div className="modal_post_grid">
                    <a href="blog-single.html">
                      <img width={852} height={812} src="assets/images/blog/blog-image-7.jpg" className="main_img wp-post-image" alt="img" />
                    </a>
                  </div>
                  <div className="modal_post_grid">
                    <a href="blog-single.html">
                      <img width={852} height={812} src="assets/images/blog/blog-image-6.jpg" className="main_img wp-post-image" alt="img" />
                    </a>
                  </div>
                  <div className="modal_post_grid">
                    <a href="blog-single.html">
                      <img width={852} height={812} src="assets/images/blog/blog-image-5.jpg" className="main_img wp-post-image" alt="img" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="copright">
                © 2023 Creote. All Rights Reserved.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/*-==============modal popup end=================*/}
  {/*-==============cart=================*/}
  <div className="side_bar_cart" id="mini_cart">
    <div className="cart_overlay" />
    <div className="cart_right_conten">
      <div className="close">
        <div className="close_btn_mini"><i className="icon-close" /></div>
      </div>
      <div className="cart_content_box">
        <div className="contnet_cart_box">
          <div className="widget_shopping_cart_content">
            <p className="woocommerce-mini-cart__empty-message">No products in the cart.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/*-==============cart=================*/}
</div>

  )
}
