import React from 'react'
import image from '../assets/pexels-pixabay-265072.jpg'
import { BsArrowRight } from "react-icons/bs";
export default function HomeBlogSection() {
  return (
    <section className="blog-section">
  {/*===============spacing==============*/}
  <div className="pd_top_75" />
  {/*===============spacing==============*/}
  <div className="container">
    <div className="row gutter_20px">
      <div className="col-lg-12 mb-5">
        <div className="title_all_box style_three text-center dark_color">
          <div className="title_sections three">
            <div className="before_title">Find Out Our</div>
            <h2>Insights &amp; Intelligence</h2>
          </div>
          {/*===============spacing==============*/}
          <div className="pd_bottom_35" />
          {/*===============spacing==============*/}
        </div>
      </div>
    </div>
    <div className="row gutter_30px">
      <div className="col-lg-12">
        <div className="blog_post_section  three_column  style_seven">
          <div className="grid_show_case grid_layout clearfix">
            <div className="grid_box _card">
              <div className="news_box style_seven">
                <div className="image_box ">
                  <img width={750} height={420} src={image} className="img-fluid" alt="img" />
                  <div className="date">October 8, 2023</div>
                </div>
                <div className="content_box ">
                  <ul>
                    <li><a href="#"><span className="fa fa-user " />Evan Thomas</a></li>
                    <li>
                      <div className="comments">
                        <i className="icon-text" />
                        <a href="blog-single.html" className="Comments are Closed">Post
                          Comment</a>
                      </div>
                    </li>
                  </ul>
                  <h2 className="title"><a href="blog-single.html" rel="bookmark">Workplace
                      problems in your business?</a></h2>
                  <p className="short_desc">Banter! Could “workplace banter” cause problems in
                    your business? The short answer? Yes.</p>
                  <a href="blog-single.html" className="read_more d-flex align-items-center gap-2">Read more<BsArrowRight  /></a>
                </div>
              </div>
            </div>
            <div className="grid_box _card">
              <div className="news_box style_seven">
                <div className="image_box ">
                  <img width={750} height={420} src={image} className="img-fluid" alt="img" />
                  <div className="date">October 8, 2023</div>
                </div>
                <div className="content_box ">
                  <ul>
                    <li><a href="#"><span className="fa fa-user " />Evan Thomas</a></li>
                    <li>
                      <div className="comments">
                        <i className="icon-text" />
                        <a href="blog-single.html" className="Comments are Closed">Post
                          Comment</a>
                      </div>
                    </li>
                  </ul>
                  <h2 className="title"><a href="blog-single.html" rel="bookmark">Workforce
                      Challenges &amp; Opportunities</a></h2>
                  <p className="short_desc">How well this mistaken ideas off denouncing pleasure
                    &amp; praisings will give you…</p>
                    <a href="blog-single.html" className="read_more d-flex align-items-center gap-2">Read more<BsArrowRight  /></a>                </div>
              </div>
            </div>
            <div className="grid_box _card">
              <div className="news_box style_seven">
                <div className="image_box ">
                  <img width={750} height={420} src={image} className="img-fluid" alt="img" />
                  <div className="date">October 8, 2023</div>
                </div>
                <div className="content_box ">
                  <ul>
                    <li><a href="#"><span className="fa fa-user " />Evan Thomas</a></li>
                    <li>
                      <div className="comments">
                        <i className="icon-text" />
                        <a href="blog-single.html" className="Comments are Closed">Post
                          Comment</a>
                      </div>
                    </li>
                  </ul>
                  <h2 className="title"><a href="blog-single.html" rel="bookmark">Why Should
                      Business Payroll Outsourcing?</a></h2>
                  <p className="short_desc">How well this mistaken ideas off denouncing pleasure
                    &amp; praisings will give you…</p>
                    <a href="blog-single.html" className="read_more d-flex align-items-center gap-2">Read more<BsArrowRight  /></a>                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/*===============spacing==============*/}
  <div className="pd_bottom_65" />
  {/*===============spacing==============*/}
</section>
  )
}
