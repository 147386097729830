import React from 'react'
import image from '../assets/pexels-pixabay-265072.jpg'
import { AiOutlineLike } from "react-icons/ai";
import { BsArrowRight } from "react-icons/bs";
export default function HomeService() {
  return (
    <section className="container-fluid bg-success bg_light_1">
    {/*===============spacing==============*/}
    <div className="pd_top_80" />
    {/*===============spacing==============*/}
    <div className="container">
      <div className="row mb-5">
        <div className="col-lg-12">
          <div className="title_all_box style_one text-center dark_color">
            <div className="title_sections">
              <div className="before_title">Leading</div>
              <h2>Effective Solutions</h2>
              <p>We work with your company to identify position requirements, implement
                recruitment programs, and <br /> initiate employee assessments that maximize
                recruitment efforts</p>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <div className="service_section grid_all three_column  news_wrapper_grid dark_color">
            <div className="grid_show_case grid_layout clearfix" style={{position: 'relative'}}>
              <div className="grid_box _card">
                <div className="service_post style_four">
                  <div className="image_box">
                    <img src={image} className="img-fluid" alt="img" />
                  </div>
                  <div className="content_in_box">
                    <div className="icon_box">
                      <span className="icon icon-thumbs-up icons" ><AiOutlineLike/></span>
                    </div>
                    <span className="icon icon-thumbs-up bg_im" />
                    <div className="content_box ">
                      <h2 className="title_service"><a href="index.html/service/talent-management/index.html" rel="bookmark">Talent
                          Management</a></h2>
                      <p className="short_desc">Once your company has hired the best employees,
                        the next step.</p>
                      <a className="read_more" href="index.html/service/talent-management/index.html"> Read
                        More<BsArrowRight className="icon-right-arrow-long" /></a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="grid_box _card">
                <div className="service_post style_four">
                  <div className="image_box">
                    <img src={image} className="img-fluid" alt="img" />
                  </div>
                  <div className="content_in_box">
                    <div className="icon_box">
                    <span className="icon icon-thumbs-up icons" ><AiOutlineLike/></span>
                    </div>
                    <span className="icon icon-thumbs-up bg_im" />
                    <div className="content_box ">
                      <h2 className="title_service"><a href="index.html/service/health-care-benefits/index.html" rel="bookmark">Health Care Benefits</a></h2>
                      <p className="short_desc">Workplace conflict can become a costly and
                        time-consuming process .</p>
                      <a className="read_more" href="index.html/service/health-care-benefits/index.html">
                        Read More<BsArrowRight className="icon-right-arrow-long" /></a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="grid_box _card">
                <div className="service_post style_four">
                  <div className="image_box">
                    <img src={image} className="img-fluid" alt="img" />
                  </div>
                  <div className="content_in_box">
                    <div className="icon_box">
                    <span className="icon icon-thumbs-up icons" ><AiOutlineLike/></span>
                    </div>
                    <span className="icon icon-thumbs-up bg_im" />
                    <div className="content_box ">
                      <h2 className="title_service"><a href="index.html/service/risk-management/index.html" rel="bookmark">Risk Management</a></h2>
                      <p className="short_desc">Mitigate workplace issues before they escalate
                        ,Shared Time Human.</p>
                      <a className="read_more" href="index.html/service/risk-management/index.html"> Read
                        More<BsArrowRight className="icon-right-arrow-long" /></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/*===============spacing==============*/}
    <div className="pd_bottom_60" />
    {/*===============spacing==============*/}
  </section>
  )
}
