import React, { useEffect } from 'react'
import image from '../assets/pexels-pixabay-265072.jpg'
import { AiOutlineRight } from 'react-icons/ai';
import { FaRegThumbsUp } from "react-icons/fa";
import { Link } from 'react-router-dom';
export default function Service() {
  useEffect (()=>{
    window.scrollTo(0, 0);
   },[])
  return (
<div id="page" className="page_wapper allmain hfeed site">

  {/*-============== wrapper_full =================*/}
  <div id="wrapper_full" className="content_all_warpper">
   
    {/*--header---*/}
   
    {/*--header end---*/}
    <div className="page_header_default style_one ">
      <div className="parallax_cover">
        <img src={image} alt="bg_image" className="cover-parallax" />
      </div>
      <div className="page_header_content">
        <div className="auto-container">
          <div className="row">
            <div className="col-md-12">
              <div className="banner_title_inner">
                <div className="title_page">
                  Service
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="breadcrumbs creote">
                <ul className="breadcrumb m-auto">
                  <li><Link to="/" href="index.html">Home</Link> </li>
                  <li><AiOutlineRight className='mb-1'/></li>
                  <li className="active">Service</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/*--header---*/}
    {/*--page-CONTENT---*/}
    <div id="content" className="site-content ">
      {/*===============spacing==============*/}
      <div className="pd_top_90" />
      {/*===============spacing==============*/}
      <section className="creote-service-box">
        <div className="container">
          <div className="row">
            <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12 col-xs-12 px-2">
              <div className="service_post style_one">
                <div className="image">
                  <div className="overlay" />
                  <img loading="lazy" width={500} height={500} src={image} alt="img" />
                </div>
                <div className="service_content icon_yes">
                  <div className="icon_box">
                    <FaRegThumbsUp className=" fs-3 text-white" />
                  </div>
                  <h2 className="title_service"><a href="#" rel="bookmark">Talent Management</a></h2>
                  <p className="short_desc">Once your company has hired the best employees, the next step.</p>
                  <a className="read_more" href="#"> Read More<i className="icon-right-arrow-long" /></a>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12 col-xs-12 px-2">
              <div className="service_post style_one">
                <div className="image">
                  <div className="overlay" />
                  <img loading="lazy" width={500} height={500} src={image} alt="img" />
                </div>
                <div className="service_content icon_yes">
                  <div className="icon_box">
                  <FaRegThumbsUp className=" fs-3 text-white" />
                  </div>
                  <h2 className="title_service"><a href="#" rel="bookmark">Health Care Benefits</a></h2>
                  <p className="short_desc">Workplace conflict can become a costly and time-consuming process.
                  </p>
                  <a className="read_more" href="#"> Read More<i className="icon-right-arrow-long" /></a>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12 col-xs-12 px-2">
              <div className="service_post style_one">
                <div className="image">
                  <div className="overlay" />
                  <img loading="lazy" width={500} height={500} src={image} alt="img" />
                </div>
                <div className="service_content icon_yes">
                  <div className="icon_box">
                  <FaRegThumbsUp className=" fs-3 text-white" />
                  </div>
                  <h2 className="title_service"><a href="#" rel="bookmark">Risk Management</a></h2>
                  <p className="short_desc">Mitigate workplace issues before they escalate ,Shared Time Human.
                  </p>
                  <a className="read_more" href="#"> Read More<i className="icon-right-arrow-long" /></a>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12 col-xs-12 px-2">
              <div className="service_post style_one">
                <div className="image">
                  <div className="overlay" />
                  <img loading="lazy" width={500} height={500} src={image} alt="img" />
                </div>
                <div className="service_content icon_yes">
                  <div className="icon_box">
                  <FaRegThumbsUp className=" fs-3 text-white" />
                  </div>
                  <h2 className="title_service"><a href="#" rel="bookmark">Compliance Audits</a></h2>
                  <p className="short_desc">Prevents our being able too what get like best every pleasure.</p>
                  <a className="read_more" href="#"> Read More<i className="icon-right-arrow-long" /></a>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12 col-xs-12 px-2">
              <div className="service_post style_one">
                <div className="image">
                  <div className="overlay" />
                  <img loading="lazy" width={500} height={500} src={image} alt="img" />
                </div>
                <div className="service_content icon_yes">
                  <div className="icon_box">
                  <FaRegThumbsUp className=" fs-3 text-white" />
                  </div>
                  <h2 className="title_service"><a href="#" rel="bookmark">Employee Relations</a></h2>
                  <p className="short_desc">Indignation sed dislike men who are beguiled and demoralized.</p>
                  <a className="read_more" href="#"> Read More<i className="icon-right-arrow-long" /></a>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12 col-xs-12 px-2">
              <div className="service_post style_one">
                <div className="image">
                  <div className="overlay" />
                  <img loading="lazy" width={500} height={500} src={image} alt="img" />
                </div>
                <div className="service_content icon_yes">
                  <div className="icon_box">
                  <FaRegThumbsUp className=" fs-3 text-white" />
                  </div>
                  <h2 className="title_service"><a href="#" rel="bookmark">Recruitment Process</a></h2>
                  <p className="short_desc">These cases are perfectly simple and easy to distinguish.</p>
                  <a className="read_more" href="#"> Read More<i className="icon-right-arrow-long" /></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*===============spacing==============*/}
      <div className="pd_top_80" />
      {/*===============spacing==============*/}
      {/*-newsteller-*/}
      <section className="newsteller style_one bg_dark_1">
        {/*===============spacing==============*/}
        <div className="pd_top_40" />
        {/*===============spacing==============*/}
        <div className="auto-container pd_top_40">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12">
              <div className="content">
                <h2>Join Our Mailing List</h2>
                <p>For receiving our news and updates in your inbox directly. </p>
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="item_scubscribe">
                <div className="input_group">
                  <form className="mc4wp-form" method="post" data-name="Subscibe">
                    <div className="mc4wp-form-fields">
                      <input type="email" name="EMAIL" placeholder="Your email address" required />
                      <input type="submit" defaultValue="Sign up" />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*===============spacing==============*/}
        <div className="pd_bottom_40" />
        {/*===============spacing==============*/}
      </section>
      {/*-newsteller end-*/}
    </div>
  </div>
 
  {/*-==============mobile menu =================*/}
  <div className="crt_mobile_menu">
    <div className="menu-backdrop" />
    <nav className="menu-box">
      <div className="close-btn"><i className="icon-close" /></div>
      <form role="search" method="get" action="#">
        <input type="search" className="search" placeholder="Search..." defaultValue name="s" title="Search" />
        <button type="submit" className="sch_btn"> <i className="icon-search" /></button>
      </form>
      <div className="menu-outer">
        {/*Here Menu Will Come Automatically Via Javascript / Same Menu as in Header*/}
      </div>
    </nav>
  </div>
  {/*-==============mobile menu end =================*/}
  {/*-==============search popup =================*/}
  <div id="search-popup" className="search-popup">
    <div className="close-search"><i className="fa fa-times" /></div>
    <div className="popup-inner">
      <div className="overlay-layer" />
      <div className="search-form">
        <fieldset>
          <form role="search" method="get" action="#">
            <input type="search" className="search" placeholder="Search..." defaultValue name="s" title="Search" />
            <button type="submit" className="sch_btn"> <i className="icon-search" /></button>
          </form>
        </fieldset>
      </div>
    </div>
  </div>
  {/*-==============search popup end =================*/}
  {/*-==============modal popup =================*/}
  <div className="modal_popup one">
    <div className="modal-popup-inner">
      <div className="close-modal"><i className="fa fa-times" /></div>
      <div className="modal_box">
        <div className="row">
          <div className="col-lg-5 col-md-12 form_inner">
            <div className="form_content">
              <form className="contact-form" method="post" action="https://themepanthers.com/html/creote-html/sendemail.php">
                <p>
                  <label> Your name<br />
                    <input type="text" name="name" defaultValue size={40} aria-required="true" aria-invalid="false" placeholder="Enter Your Name" /> 
                    <br />
                    <i className="fa fa-user" /><br />
                  </label>
                </p>
                <p><label> Your email<br />
                    <input type="email" name="email" defaultValue size={40} aria-required="true" aria-invalid="false" placeholder="Enter Your Email" />
                    <br />
                    <i className="fa fa-envelope" /><br />
                  </label>
                </p>
                <p>
                  <label> Subject<br />
                    <input type="text" name="subject" defaultValue size={40} aria-required="true" aria-invalid="false" placeholder="Enter Your Subject" /> 
                    <br />
                    <i className="fa fa-folder" /><br />
                  </label>
                </p>
                <p>
                  <label> Your message (optional)<br />
                    <textarea name="message" cols={40} rows={10} className="wpcf7-form-control wpcf7-textarea" aria-invalid="false" placeholder="Enter Your Message" defaultValue={""} /> 
                    <br />
                    <i className="fa fa-comments" /><br />
                  </label>
                </p>
                <p><input type="submit" defaultValue="Submit" /></p>
              </form>
            </div>
          </div>
          <div className="col-lg-7 col-md-12 about_company_inner">
            <div className="abt_content">
              <div className="logo">
                <img src="assets/images/logo-default.png" alt="img" className="company_logo_modal" />
              </div>
              <div className="text">
                <p> The great explorer of the truth, the master-builder of human happiness no one rejects
                  dislikes avoids pleasure itself because it is pleasure but because know who do not those
                  how to pursue pleasures rationally encounter consequences that are extremely painful
                  desires to obtain.</p>
                <a href="#">Read More</a>
              </div>
              <div className="post_contet_modal">
                <h2> Latest News</h2>
                <div className="post_enable">
                  <div className="modal_post_grid">
                    <a href="blog-single.html">
                      <img width={852} height={812} src="assets/images/blog/blog-image-9.jpg" className="main_img wp-post-image" alt="img" />
                    </a>
                  </div>
                  <div className="modal_post_grid">
                    <a href="blog-single.html">
                      <img width={852} height={812} src="assets/images/blog/blog-image-8.jpg" className="main_img wp-post-image" alt="img" />
                    </a>
                  </div>
                  <div className="modal_post_grid">
                    <a href="blog-single.html">
                      <img width={852} height={812} src="assets/images/blog/blog-image-7.jpg" className="main_img wp-post-image" alt="img" />
                    </a>
                  </div>
                  <div className="modal_post_grid">
                    <a href="blog-single.html">
                      <img width={852} height={812} src="assets/images/blog/blog-image-6.jpg" className="main_img wp-post-image" alt="img" />
                    </a>
                  </div>
                  <div className="modal_post_grid">
                    <a href="blog-single.html">
                      <img width={852} height={812} src="assets/images/blog/blog-image-5.jpg" className="main_img wp-post-image" alt="img" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="copright">
                © 2023 Creote. All Rights Reserved.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/*-==============modal popup end=================*/}
  {/*-==============cart=================*/}
  <div className="side_bar_cart" id="mini_cart">
    <div className="cart_overlay" />
    <div className="cart_right_conten">
      <div className="close">
        <div className="close_btn_mini"><i className="icon-close" /></div>
      </div>
      <div className="cart_content_box">
        <div className="contnet_cart_box">
          <div className="widget_shopping_cart_content">
            <p className="woocommerce-mini-cart__empty-message">No products in the cart.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/*-==============cart=================*/}
</div>

  )
}
