import React from 'react'
import '../Style.css'
import image from '../assets/pexels-pixabay-265072.jpg'
import { BiLogoFacebook } from "react-icons/bi";
import { FaXTwitter } from "react-icons/fa6";
import { AiFillSkype } from "react-icons/ai";
import { FaTelegram } from "react-icons/fa";
import { HiShare } from "react-icons/hi";
export default function HomeTeamSection() {
  return (
    
<section className="team-section" style={{background:"#fbf9f7"}}>
  {/*===============spacing==============*/}
  <div className="pd_top_80" />
  {/*===============spacing==============*/}
  <div className="container-fluid">
    <div className="row">
      <div className="col-lg-12">
        <div className="title_all_box style_one text-center dark_color">
          <div className="title_sections mb-5 pb-4">
            <div className="before_title">Dedicated Team</div>
            <h2>Professional Individuals</h2>
            <p className='teamheadpara'>Our team<br/>
members are our<br/>
greatest asset</p>
          </div>
        </div>
      </div>
    </div>
    <div className="row">
    <div className="col-xl-3 col-lg-5 col-md-6 col-sm-12 mb-sm-5 mb-md-5 mb-lg-5 mb-xl-0 ">
        <div className="team_box style_three mx-3 bg-white pb-4">
          <div className="team_box_outer">
            <div className="image_box ">
              <img src={image} alt="team image" />
              <div className="share_links ">
                <a href="#" className="shar_icon "><HiShare className="fa fa-share-alt " /></a>
                <ul className="clearfix ">
                  <li><a href="#"> <BiLogoFacebook className="fa fa-facebook"/> </a></li>
                  <li><a href="#"> <FaXTwitter className="fa fa-twitter"/> </a></li>
                  <li><a href="#"> <AiFillSkype className="fa fa-twitter"/> </a></li>
                  <li><a href="#"> <FaTelegram className="fa fa-twitter"/> </a></li>
                </ul>
              </div>
            </div>
            <div className="content_box px-3">
              <p className='fs-1 fw-bold py-3'>Amelia Margaret</p>
              <h6 className="job_details">Director</h6>
              <p className='py-2 fs-4 border-top border-bottom border-gray border-2' >The HR Manger of Creote, he is very intelligent and smart. </p>
            </div>
          </div>
        </div>
      </div>

      <div className="col-xl-3 col-lg-5 col-md-6 col-sm-12 mb-sm-5 mb-md-5 mb-lg-5 mb-xl-0 ">
        <div className="team_box style_three mx-3 bg-white pb-4">
          <div className="team_box_outer">
            <div className="image_box ">
              <img src={image} alt="team image" />
              <div className="share_links ">
                <a href="#" className="shar_icon "><HiShare className="fa fa-share-alt " /></a>
                <ul className="clearfix ">
                  <li><a href="#"> <BiLogoFacebook className="fa fa-facebook"/> </a></li>
                  <li><a href="#"> <FaXTwitter className="fa fa-twitter"/> </a></li>
                  <li><a href="#"> <AiFillSkype className="fa fa-twitter"/> </a></li>
                  <li><a href="#"> <FaTelegram className="fa fa-twitter"/> </a></li>
                </ul>
              </div>
            </div>
            <div className="content_box px-3">
              <p className='fs-1 fw-bold py-3'>Amelia Margaret</p>
              <h6 className="job_details">Director</h6>
              <p className='py-2 fs-4 border-top border-bottom border-gray border-2' >The HR Manger of Creote, he is very intelligent and smart. </p>
            </div>
          </div>
        </div>
      </div>

      <div className="col-xl-3 col-lg-5 col-md-6 col-sm-12 mb-sm-5 mb-md-5 mb-lg-5 mb-xl-0 ">
        <div className="team_box style_three mx-3 bg-white pb-4">
          <div className="team_box_outer">
            <div className="image_box ">
              <img src={image} alt="team image" />
              <div className="share_links ">
                <a href="#" className="shar_icon "><HiShare className="fa fa-share-alt " /></a>
                <ul className="clearfix ">
                  <li><a href="#"> <BiLogoFacebook className="fa fa-facebook"/> </a></li>
                  <li><a href="#"> <FaXTwitter className="fa fa-twitter"/> </a></li>
                  <li><a href="#"> <AiFillSkype className="fa fa-twitter"/> </a></li>
                  <li><a href="#"> <FaTelegram className="fa fa-twitter"/> </a></li>
                </ul>
              </div>
            </div>
            <div className="content_box px-3">
              <p className='fs-1 fw-bold py-3'>Amelia Margaret</p>
              <h6 className="job_details">Director</h6>
              <p className='py-2 fs-4 border-top border-bottom border-gray border-2' >The HR Manger of Creote, he is very intelligent and smart. </p>
            </div>
          </div>
        </div>
      </div>

      <div className="col-xl-3 col-lg-5 col-md-6 col-sm-12 mb-sm-5 mb-md-5 mb-lg-5 mb-xl-0 ">
        <div className="team_box style_three mx-3 bg-white pb-4">
          <div className="team_box_outer">
            <div className="image_box ">
              <img src={image} alt="team image" />
              <div className="share_links ">
                <a href="#" className="shar_icon "><HiShare className="fa fa-share-alt " /></a>
                <ul className="clearfix ">
                  <li><a href="#"> <BiLogoFacebook className="fa fa-facebook"/> </a></li>
                  <li><a href="#"> <FaXTwitter className="fa fa-twitter"/> </a></li>
                  <li><a href="#"> <AiFillSkype className="fa fa-twitter"/> </a></li>
                  <li><a href="#"> <FaTelegram className="fa fa-twitter"/> </a></li>
                </ul>
              </div>
            </div>
            <div className="content_box px-3">
              <p className='fs-1 fw-bold py-3'>Amelia Margaret</p>
              <h6 className="job_details">Director</h6>
              <p className='py-2 fs-4 border-top border-bottom border-gray border-2' >The HR Manger of Creote, he is very intelligent and smart. </p>
            </div>
          </div>
        </div>
      </div>

      
    </div>
  </div>
  {/*===============spacing==============*/}
  <div className="pd_bottom_80" />
  {/*===============spacing==============*/}
</section>
  )
}
