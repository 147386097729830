const assets = {
    PravinVaidya:require('./pexels-pixabay-265072.jpg'),
    AboutUsImage1:require('./pexels-pixabay-265072.jpg'),
    About_Us_Image1:require('./pexels-pixabay-265072.jpg'),
    AboutUsImage2:require('./pexels-pixabay-265072.jpg'),

    AboutUsVedgurukulOnlineEdu:require('./pexels-pixabay-265072.jpg'),
    AboutUsImageVedgurukulHolisticApproach :require('./pexels-pixabay-265072.jpg'),
    AboutUsVedgurukulExpertFaculty:require('./pexels-pixabay-265072.jpg'),
    AboutUsImageVedgurukulAccessibleAndInclusive :require('./pexels-pixabay-265072.jpg'),
    AboutUsVedgurukulDrivingInnovation:require('./pexels-pixabay-265072.jpg'),
    AboutUsImageVedgurukulJoinTheVedgurukul  :require('./pexels-pixabay-265072.jpg'),

    AboutUsImageMyNetaIntegratedManagement1  :require('./pexels-pixabay-265072.jpg'),
    AboutUsImageMyNetaIntegratedManagement2 :require('./pexels-pixabay-265072.jpg'),
    AboutUsImageMyNetaIntegratedManagement3  :require('./pexels-pixabay-265072.jpg'),   
    AboutUsImageMyNetaAgendaSolving1  :require('./pexels-pixabay-265072.jpg'),
    AboutUsImageMyNetaAgendaSolving2  :require('./pexels-pixabay-265072.jpg'),
    AboutUsImageMyNetaAgendaSolving3  :require('./pexels-pixabay-265072.jpg'),
    AboutUsImageMyNetaLiveTelecasts1  :require('./pexels-pixabay-265072.jpg'),
    AboutUsImageMyNetaLiveTelecasts2  :require('./pexels-pixabay-265072.jpg'),
    AboutUsImageMyNetaLiveTelecasts3  :require('./pexels-pixabay-265072.jpg'),
    AboutUsImageMyNetaEmpoweringDemocracy1  :require('./pexels-pixabay-265072.jpg'),
    AboutUsImageMyNetaEmpoweringDemocracy2 :require('./pexels-pixabay-265072.jpg'),
    AboutUsImageMyNetaEmpoweringDemocracy3  :require('./pexels-pixabay-265072.jpg'),

    AboutUsImageAdsGramDynamicAdPlacement :require('./pexels-pixabay-265072.jpg'),
    AboutUsImageAdsGramTailoredSolutions :require('./pexels-pixabay-265072.jpg'),
    AboutUsImageAdsGramCuttingEdge :require('./pexels-pixabay-265072.jpg'),
    AboutUsImageAdsGramMeasurableResults  :require('./pexels-pixabay-265072.jpg'),
    AboutUsImageAdsGramJoinTheAdsGram :require('./pexels-pixabay-265072.jpg'),
    
    BlogAstitva:require('./pexels-pixabay-265072.jpg'),
    BlogAdsGram:require('./pexels-pixabay-265072.jpg'),
    BlogMyNeta:require('./pexels-pixabay-265072.jpg'),
    BlogClubCare:require('./pexels-pixabay-265072.jpg'),
    BlogAstitvaReality:require('./pexels-pixabay-265072.jpg'),
    BlogAstitvaMart:require('./pexels-pixabay-265072.jpg'),
    BlogDigiGram:require('./pexels-pixabay-265072.jpg'),
    BlogVedgurukul:require('./pexels-pixabay-265072.jpg'),
    BlogKrishiFarm:require('./pexels-pixabay-265072.jpg'),
    BlogFilmyAdda:require('./pexels-pixabay-265072.jpg'), 
    BlogInsideAstitva:require('./pexels-pixabay-265072.jpg'),   
    BlogInsideAdsGram:require('./pexels-pixabay-265072.jpg'),   
    BlogInsideMyNeta:require('./pexels-pixabay-265072.jpg'),   
    BlogInsideClubCare:require('./pexels-pixabay-265072.jpg'),   
    BlogInsideAstitvaReality:require('./pexels-pixabay-265072.jpg'),   
    BlogInsideAstitvaMart:require('./pexels-pixabay-265072.jpg'),   
    BlogInsideDigiGram:require('./pexels-pixabay-265072.jpg'),   
    BlogInsideVedgurukul:require('./pexels-pixabay-265072.jpg'),   
    BlogInsideKrishiFarm:require('./pexels-pixabay-265072.jpg'),   
    BlogInsideFilmyAdda:require('./pexels-pixabay-265072.jpg'),   
        
}
export default assets;