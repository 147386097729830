import React, { useMemo, useState } from 'react'
import '../Style.css'

import { IoLocationOutline } from "react-icons/io5";
import { MdEmail } from "react-icons/md";
import { BiPhoneCall } from "react-icons/bi";
import { HiShare } from "react-icons/hi";
import { BiLogoFacebook } from "react-icons/bi";
import { FaXTwitter } from "react-icons/fa6";
import { Youtube } from 'lucide-react';
import { FaTelegram } from "react-icons/fa";
import '../JQUERY/Jquery'
import Navbar from '../Pages/Navbar';
export default function NavbarForAllPages({logo}) {

  return (
    <>
   
<div className='HomeHeader container-fluid bg-info d-flex justify-content-center'>
        <div className="HomeHeaderBox col-sm-12 col-lg-12 col-xl-12  px-0 overflow-hidden" >

        <div className='topBar col-12 d-flex flex-wrap py-4  text-white' style={{backgroundColor:"#078586"}}>
          <div className='col-lg-7 col-xl-8 d-flex flex-wrap col-md-12 align-items-center'>
              <div className=' itemCenter col-lg-6 col-xl-6 col-12 d-flex flex-wrap justify-content-center align-items-center'><small><IoLocationOutline className='allIcons'/> Location </small>
                      <span style={{color:"#aaafb8"}}>&nbsp; 61W Business Str Hobert, LA </span>
              </div>

              <div className='itemCenter col-lg-6 col-xl-6 col-12 d-flex align-items-center'>
              <small><MdEmail className='allIcons'/> Email &nbsp;</small>
                      <a href="mailto:sendmail@creote.com" className='nav-link' style={{color:"#aaafb8"}}>sendmail@creote.com</a>
              </div>
          </div>


          <div className='col-lg-4 col-xl-4 d-flex flex-wrap col-12 align-items-center'>
              <div className='itemCenter col-lg-6 col-xl-6 col-12 d-flex  flex-wrap justify-content-center align-items-center'> <small><BiPhoneCall className='allIcons'/> Phone</small>
                      <a href="tel:+9806071234" className='nav-link' style={{color:"#aaafb8"}}>+9806071234</a>
              </div>
              <div className='itemCenter col-lg-6 col-xl-6 col-12 d-flex gap-2 align-items-center'>&nbsp;
              <small className='d-flex gap-1 align-items-center'><HiShare className='allIcons '/> Share</small>
                      <a href="#" target="_blank" className='nav-link' rel="nofollow">
                        <small><BiLogoFacebook/></small>
                      </a>
                      <a href="#" target="_blank" className='nav-link' rel="nofollow">
                        <small><FaXTwitter/></small>
                      </a>
                      <a href="#" target="_blank" className='nav-link' rel="nofollow">
                        <small><Youtube/></small>
                      </a>
                      <a href="#" target="_blank" className='nav-link' rel="nofollow">
                        <small><FaTelegram/></small>
                      </a>
              </div>
          </div>
      </div>



            <div className="">
                <Navbar/>
            </div>
</div>
    </div>
     
    </>
  )
}
