import React from 'react'
import { BrowserRouter,Routes,Route } from 'react-router-dom'
import Home from './Home'
import Footer from './Footer'
import Contact from './Contact'
import Aboutus from './Aboutus'
import Navbar from './Navbar'
import NavbarForAllPages from '../Components/NavbarForAllPages'
import Service from './Service'
import Blog from './Blog'
import BlogPages from './BlogPages'

export default function Main() {
  
  return (
    <>
      <BrowserRouter>
      <NavbarForAllPages/>
        <Routes>     
          <Route path='/' element={<Home />} />  
          <Route path='/aboutus' element={ <Aboutus />} />
          <Route path='/blog' element={ <Blog />} />
          <Route path='/blogpage/:name' element={ <BlogPages />} />
          <Route path='/contact' element={ <Contact />} />
          <Route path='/service' element={ <Service />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </>
  )
}
