import React, { useEffect } from 'react';
import '../Style.css'
import image from '../assets/pexels-pixabay-265072.jpg'
import blogPostApi from '../API/BlogPostApi';
import { Link, useParams } from 'react-router-dom';
import { AiOutlineRight } from 'react-icons/ai';
import { BiLogoWhatsapp } from "react-icons/bi";
import img from '../assets/quotes.png'
import { Youtube } from 'lucide-react';
import { BiLogoFacebook } from "react-icons/bi";
import { FaXTwitter } from "react-icons/fa6";
import { FaTelegram } from "react-icons/fa";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import assets from '../assets/asse';


export default function BlogPages() {
  
  useEffect (()=>{
    window.scrollTo(0, 0);
   },[])
  const { name } = useParams();

  const data = blogPostApi.find(blog => blog.title === name);


  return (
  <div id="page" className="page_wapper hfeed site">

  {/*-============== wrapper_full =================*/}
  <div id="wrapper_full" className="content_all_warpper">
   
    {/*--header---*/}

    {/*--header end---*/}
    <div className="page_header_default style_one blog_single_pageheader">
      <div className="parallax_cover">
        <div className="simpleParallax"><img src={data.images} alt="bg_image" className="img-fluid" /></div>
      </div>
      <div className="page_header_content">
        <div className="auto-container">
          <div className="row">
            <div className="col-md-12">
              <div className="banner_title_inner">
                <div className="title_page">
                  {data.title}
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="breadcrumbs creote">
                <ul className="breadcrumb m-auto">
                <li><Link to="/">Home</Link></li>
                  <li><AiOutlineRight className='mb-1'/></li>
                  <li ><Link to="/blog">Blog</Link></li>
                  <li><AiOutlineRight className='mb-1'/></li>
                  <li className="active">{data.title}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="meta_blog_single">
        <div className="auto-container">
          <div className="row">
            <div className="col-md-12">
              <div className="left_side">
                <div className="category"><a href="#" className="categors"><i className="icon-folder" />Coaching</a>
                </div>
                <div className="comments">
                  <i className="icon-text" />
                  <a href="#comment" className="Comments are Closed">Post Comment</a>
                </div>
              </div>
              <div className="right_side">
                <div className="content_box_auht d-flex">
                  <div className="authour_content">
                    <h6>Posted By</h6>
                    <h4>Pravin Vaidya</h4>
                  </div>
                  <div className="authour_image">
                    <img alt="img" src={process.env.REACT_APP_PRAVINVAIDYA} height={50} width={50} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/*--header---*/}
    {/*--page-CONTENT---*/}
    <div id="content" className="site-content ">
      <div className="auto-container">
        <div className="row default_row">
          <div id="primary" className="content-area service col-lg-8 col-md-12 col-sm-12 col-xs-12">
            <main id="main" className="site-main" role="main">
              {/*===============spacing==============*/}
              <div className="pd_top_90" />
              {/*===============spacing==============*/}
              <section className="blog_single_details_outer px-3">
                <div className="single_content_upper">
                  <div className="blog_feature_image">
                    <img src={image} className="wp-post-image" alt="img" />
                  </div>
                  {/*===============spacing==============*/}
                  <div className="pd_bottom_20" />
                  {/*===============spacing==============*/}
                  <div className="post_single_content">
                    <h5>{data.overview}</h5>
                    {/*===============spacing==============*/}
                    <div className="pd_bottom_15" />
                    {/*===============spacing==============*/}
                    <div className="description_box">
                      <p>{data.desc}</p>
                    </div>
                    {/*===============spacing==============*/}
                    <div className="pd_bottom_15" />
                    {/*===============spacing==============*/}
                    <h3 className='text-decoration-underline'>Description</h3>
                    {/*===============spacing==============*/}
                    <div className="pd_bottom_15" />
                    {/*===============spacing==============*/}
                    <div className="description_box">
                      <p>{data.desc}</p>
                    </div>
                    {/*===============spacing==============*/}
                    <div className="pd_bottom_25" />
                    {/*===============spacing==============*/}
                    <div className="quotes_box style_one">
                      <div className="icon">
                        <img src={img} className="svg_image" alt="icon png" />
                      </div>
                      <div className="content">
                        <h6>The key for us, number one, has always been hiring very smart people.</h6>
                        <h3>Lamont Shaun</h3>
                      </div>
                    </div>
                    {/*===============spacing==============*/}
                    <div className="pd_bottom_25" />
                    {/*===============spacing==============*/}
                    {/* <div className="description_box">
                      <p>Foresee the pain and trouble that are bound to ensue; and equal blame belongs to
                        those who fail in their duty through weakness of will, which is the same as
                        saying through shrinking from toil and pain. These cases are perfectly simple and
                        easy to distinguish.</p>
                    </div> */}
                    {/*===============spacing==============*/}
                    <div className="pd_bottom_15" />
                    {/*===============spacing==============*/}
                    <h3 className='text-decoration-underline'>MISSION:</h3>
                    {/*===============spacing==============*/}
                    <div className="pd_bottom_15" />
                    {/*===============spacing==============*/}
                    <div className="description_box">
                      <p>{data.mission}</p>
                    </div>
                    {/*===============spacing==============*/}
                    <div className="pd_bottom_15" />
                    {/*===============spacing==============*/}
                        <h3 className='text-decoration-underline'>VISION:</h3>

                       {/*===============spacing==============*/}
                       <div className="pd_bottom_15" />
                    {/*===============spacing==============*/}
                    <div className="description_box">
                      <p>{data.vision}</p>
                    </div>
                    {/*===============spacing==============*/}
                    <div className="pd_bottom_15" />
                    {/*===============spacing==============*/}
                    {/* <div className="description_box">
                      <p>Man who chooses to enjoy a pleasure that has no annoying consequences:</p>
                    </div> */}
                    {/*===============spacing==============*/}
                    <div className="pd_bottom_15" />
                    {/*===============spacing==============*/}
                    {/* <div className="content_box_cn style_one">
                      <div className="txt_content">
                        <h3>
                          <a href="#" target="_blank" rel="nofollow">Reducing Redundancy:</a>
                        </h3>
                        <p>
                          Equal blame belongs to those who fail in their duty through weakness same
                          duty.
                        </p>
                      </div>
                    </div> */}
                    {/*===============spacing==============*/}
                    <div className="pd_bottom_15" />
                    {/*===============spacing==============*/}
                    {/* <div className="content_box_cn style_one">
                      <div className="txt_content">
                        <h3>
                          <a href="#" target="_blank" rel="nofollow">Greater Retension Rates:</a>
                        </h3>
                        <p>
                          Business it will frequently occur that pleasures have to be repudiated
                          annoyances accepted.
                        </p>
                      </div>
                    </div> */}
                    {/*===============spacing==============*/}
                    <div className="pd_bottom_15" />
                    {/*===============spacing==============*/}
                    {/* <div className="content_box_cn style_one">
                      <div className="txt_content">
                        <h3>
                          <a href="#" target="_blank" rel="nofollow">Handling Employement:</a>
                        </h3>
                        <p>
                          Holds in these matters to this principle of selection: he rejects pleasures to
                          secure.
                        </p>
                      </div>
                    </div> */}
                    {/*===============spacing==============*/}
                    {/* <div className="pd_bottom_15" /> */}
                    {/*===============spacing==============*/}
                    <div className="row gutter_30px">
                      <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-5 mb-lg-5 mb-xl-0">
                        <div className="simple_image_boxes">
                          <img src={data.images} className="simp_img img-fluid height_540px" alt="image" />
                        </div>
                        {/*===============spacing==============*/}
                        {/* <div className="pd_bottom_30" /> */}
                        {/*===============spacing==============*/}
                      </div>
                      <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div className="simple_image_boxes">
                          <img src={data.images} className="simp_img img-fluid height_260" alt="image" />
                        </div>
                        {/*===============spacing==============*/}
                        <div className="pd_bottom_30" />
                        {/*===============spacing==============*/}
                        <div className="simple_image_boxes ">
                          <img src={data.images} className="simp_img img-fluid height_250px" alt="image" />
                        </div>
                      </div>
                    </div>
                    <div className="invisible_normal_spacing" />
                    {/* <div className="description_box">
                      <p>Duty through weakness of will, which is the same as saying through shrinking from
                        toil and pain. These cases are perfectly simple and easy to distinguish. In a
                        free hour, when our power of choice is untrammelled and when nothing prevents our
                        to do what we like best.</p>
                    </div> */}
                  </div>
                </div>
                <div className="single_content_lower">
                  <div className="tags_and_share">
                    <div className="d-flex">
                      <div className="tags_content left_one">
                        <div className="box_tags_psot">
                          <div className="title">Tags</div>
                          <a className="btn" href="#">#Appraisal</a>
                          <a className="btn" href="#">#Contract</a>
                        </div>
                      </div>
                      <div className="share_content right_one">
                        <div className="share_socail">
                          <div className="title">Share</div>
                          <button className="m_icon" title="facebook" data-sharer="facebook" data-title="blog single" data-url="blog-single.html">
                            <BiLogoFacebook className="fa fa-facebook" />
                          </button>
                          <button className="m_icon" title="twitter" data-sharer="twitter" data-title="blog single" data-url="blog-single.html">
                            <FaXTwitter className="fa fa-twitter" />
                          </button>
                          <button className="m_icon" title="whatsapp" data-sharer="whatsapp" data-title="blog single" data-url="blog-single.html">
                            <BiLogoWhatsapp className="fs-4" />
                          </button>
                          <button className="m_icon" title="telegram" data-sharer="telegram" data-title="blog single" data-url="blog-single.html" data-to="+44555-03564">
                            <FaTelegram className="fa fa-telegram" />
                          </button>
                          <button className="m_icon" title="youtubbe" data-sharer="youtube" data-url="blog-single.html" data-title="blog single">
                            <Youtube  className="" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="sec_comments padding-t-8 padding-b-3" id="comment">
                    <div className="container_no">
                      <div className="row justify-content-center">
                        <div className="col-lg-12">
                          <div id="respond" className="comment-respond">
                            <h3 id="reply-title" className="comment-reply-title">
                              Post a comment 
                            </h3>
                            <form action="#" method="post" id="commentform" className="comment-form">
                              <p className="title_para">Your email address will not be published.</p>
                              <p className="comment-form-comment"><label htmlFor="comment">Leave a
                                  Reply</label><textarea placeholder="Write your comment here..." id="comment" name="comment" rows={12} aria-required="true" defaultValue={""} /></p>
                              <p className="comment-form-author">
                                <label htmlFor="name">Full Name</label>
                                <input id="author" placeholder="ex. John Doe" name="author" type="text" defaultValue size={30} aria-required="true" />
                              </p>
                              <p className="comment-form-email">
                                <label htmlFor="comment">Email address</label>
                                <input id="email" placeholder="ex. john@mail.com" name="email" type="text" defaultValue size={30} aria-required="true" />
                              </p>
                              <p className="comment-form-url">
                                <label htmlFor="name">Website Url</label>
                                <input id="url" name="url" placeholder="ex. www.example.com" type="text" defaultValue size={30} />
                              </p>
                              <p className="custom-control custom-checkbox">
                                <input id="wp-comment-cookies-consent" className="custom-control-input" name="wp-comment-cookies-consent" type="checkbox" defaultValue />
                                <label className="custom-control-label pl-1 c-gray" htmlFor="wp-comment-cookies-consent">Save my name, and email in this
                                  browser for the next time I comment.</label>
                              </p>
                              <div className="clearfix" />
                              <p className="form-submit">
                                <input name="submit" type="submit" id="submit" className="submit" defaultValue="Post  comment" />
                                <input type="hidden" name="comment_post_ID" defaultValue={350} id="comment_post_ID" />
                                <input type="hidden" name="comment_parent" id="comment_parent" defaultValue={0} />
                              </p>
                            </form>
                          </div>
                          {/* #respond */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="related_post">
                  <div className="title_sections_inner">
                    <h2>Related Posts</h2>
                  </div>
                  {/* Swiper */}
                  <Swiper
                   centeredSlides={true}
          spaceBetween={30}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          navigation={true}
          modules={[Autoplay, Pagination, Navigation]}
      
          breakpoints={{
            1200: {
              slidesPerView: 2
            },
            1024: {
              slidesPerView: 2
            },
            768: {
              slidesPerView: 3
            },
            576: {
              slidesPerView: 3
            },
            250: {
              slidesPerView: 2
            },
            0: {
              slidesPerView: 1
            }
          }}
         
          
          className="mySwiper"
        >
          <SwiperSlide>            
          <div className="">
                        <div className="news_box default_style list_view normal_view clearfix has_images">
                          <div className="image img_hover-1">
                            <img src={image}className="img-fluid" alt="img" />
                            <a href="#" className="categories">
                              <i className="icon-folder" />Compliance Audits
                            </a>
                          </div>
                          <div className="content_box">
                            <div className="date">
                              <span className="date_in_number">October 8, 2023</span>
                            </div>
                            <div className="source">
                              <h2 className="title"><a href="blog-single.html" rel="bookmark">How to
                                  Handle Employee</a></h2>
                              <p className="short_desc">How well this mistaken ideas off denouncing
                                pleasure &amp; praisings will give you complete.</p>
                            </div>
                          </div>
                        </div>
                      </div>
                </SwiperSlide>
                <SwiperSlide>            
               <div className="swiper-slide">
                        <div className="news_box default_style list_view normal_view clearfix has_images">
                          <div className="image img_hover-1">
                            <img src={image} className="img-fluid" alt="img" />
                            <a href="#" className="categories">
                              <i className="icon-folder" />Compliance Audits
                            </a>
                          </div>
                          <div className="content_box">
                            <div className="date">
                              <span className="date_in_number">October 8, 2023</span>
                            </div>
                            <div className="source">
                              <h2 className="title"><a href="blog-single.html" rel="bookmark">How to
                                  Handle Employee</a></h2>
                              <p className="short_desc">How well this mistaken ideas off denouncing
                                pleasure &amp; praisings will give you complete.</p>
                            </div>
                          </div>
                        </div>
                      </div>
                </SwiperSlide>
                <SwiperSlide>            
               <div className="swiper-slide">
                        <div className="news_box default_style list_view normal_view clearfix has_images">
                          <div className="image img_hover-1">
                            <img src={image}className="img-fluid" alt="img" />
                            <a href="#" className="categories">
                              <i className="icon-folder" />Compliance Audits
                            </a>
                          </div>
                          <div className="content_box">
                            <div className="date">
                              <span className="date_in_number">October 8, 2023</span>
                            </div>
                            <div className="source">
                              <h2 className="title"><a href="blog-single.html" rel="bookmark">How to
                                  Handle Employee</a></h2>
                              <p className="short_desc">How well this mistaken ideas off denouncing
                                pleasure &amp; praisings will give you complete.</p>
                            </div>
                          </div>
                        </div>
                      </div>
                </SwiperSlide>
                <SwiperSlide>            
               <div className="swiper-slide">
                        <div className="news_box default_style list_view normal_view clearfix has_images">
                          <div className="image img_hover-1">
                            <img src={image} className="img-fluid" alt="img" />
                            <a href="#" className="categories">
                              <i className="icon-folder" />Compliance Audits
                            </a>
                          </div>
                          <div className="content_box">
                            <div className="date">
                              <span className="date_in_number">October 8, 2023</span>
                            </div>
                            <div className="source">
                              <h2 className="title"><a href="blog-single.html" rel="bookmark">How to
                                  Handle Employee</a></h2>
                              <p className="short_desc">How well this mistaken ideas off denouncing
                                pleasure &amp; praisings will give you complete.</p>
                            </div>
                          </div>
                        </div>
                      </div>
                </SwiperSlide>
                <SwiperSlide>            
               <div className="swiper-slide">
                        <div className="news_box default_style list_view normal_view clearfix has_images">
                          <div className="image img_hover-1">
                            <img src={image} className="img-fluid" alt="img" />
                            <a href="#" className="categories">
                              <i className="icon-folder" />Compliance Audits
                            </a>
                          </div>
                          <div className="content_box">
                            <div className="date">
                              <span className="date_in_number">October 8, 2023</span>
                            </div>
                            <div className="source">
                              <h2 className="title"><a href="blog-single.html" rel="bookmark">How to
                                  Handle Employee</a></h2>
                              <p className="short_desc">How well this mistaken ideas off denouncing
                                pleasure &amp; praisings will give you complete.</p>
                            </div>
                          </div>
                        </div>
                      </div>
                </SwiperSlide>
                <SwiperSlide>            
               <div className="swiper-slide">
                        <div className="news_box default_style list_view normal_view clearfix has_images">
                          <div className="image img_hover-1">
                            <img src={image} className="img-fluid" alt="img" />
                            <a href="#" className="categories">
                              <i className="icon-folder" />Compliance Audits
                            </a>
                          </div>
                          <div className="content_box">
                            <div className="date">
                              <span className="date_in_number">October 8, 2023</span>
                            </div>
                            <div className="source">
                              <h2 className="title"><a href="blog-single.html" rel="bookmark">How to
                                  Handle Employee</a></h2>
                              <p className="short_desc">How well this mistaken ideas off denouncing
                                pleasure &amp; praisings will give you complete.</p>
                            </div>
                          </div>
                        </div>
                      </div>
                </SwiperSlide>
         
               
       
          </Swiper>
            
                 
                </div>
              </section>
              {/*===============spacing==============*/}
              <div className="pd_bottom_10" />
              {/*===============spacing==============*/}
            </main>
          </div>
          <aside id="secondary" className="widget-area pd_top_70 all_side_bar px-3 col-lg-4 col-md-12 col-sm-12">
            <div className="side_bar">
              {/*===============spacing==============*/}
              {/* <div className="pd_top_90" /> */}
              {/*===============spacing==============*/}
              <div className="widgets_grid_box">
                <form role="search" method="get" action="https://themepanthers.com/html/creote-html/index.html/">
                  <div className="wp-block-search__inside-wrapper ">
                    <input type="search" name="s" defaultValue placeholder="Key Words here" required disabled />
                    <i className="fa fa-search" />
                  </div>
                </form>
              </div>
              <div className="widgets_grid_box">
                <h2 className="widget-title">About Authour</h2>
                <div className="about_authour_widget">
                  <h3>Hi! I’m Jacob Leonado</h3>
                  <img src={image} alt="authourimage" />
                  <p>Obligations of business will frequently occur that pleasure have too repudiated.</p>
                  <a href="#">All My Post</a>
                </div>
              </div>
              <div className="widgets_grid_box">
                <h2 className="widget-title">Post Categories</h2>
                <ul className="wp-block-categories">
                  <li><a href="#">Beds</a> (1)</li>
                  <li><a href="#">Coaching</a> (5)</li>
                  <li><a href="#">Compliance Audits</a> (5)</li>
                  <li><a href="#">Employee Relations</a> (8)</li>
                  <li><a href="#">Furnitures</a> (1)</li>
                  <li><a href="#">HR Consulting</a> (7)</li>
                  <li><a href="#">Lights</a> (1)</li>
                  <li><a href="#">Recruiting</a> (6)</li>
                  <li><a href="#">Small Business HR</a> (7)</li>
                  <li><a href="#">Tables</a> (1)</li>
                </ul>
              </div>
              <div className="widgets_grid_box">
                <h2 className="widget-title">Recent Posts</h2>
                <div className="widget_post_box">
                  <div className="blog_in clearfix image_in">
                    <div className="image">
                      <img decoding="async" src={image} alt="img" />
                    </div>
                    <div className="content_inner">
                      <p className="post-date"><span className="icon-calendar" />October 8, 2023</p>
                      <h3><a href="blog-single.html">Why Should Business Payroll Outsourcing?</a></h3>
                    </div>
                  </div>
                  <div className="blog_in clearfix image_in">
                    <div className="image">
                      <img decoding="async" src={image} alt="img" />
                    </div>
                    <div className="content_inner">
                      <p className="post-date"><span className="icon-calendar" />October 8, 2023</p>
                      <h3><a href="blog-single.html">Most Employees Support Measures</a></h3>
                    </div>
                  </div>
                  <div className="blog_in clearfix image_in">
                    <div className="image">
                      <img decoding="async" src={image} alt="img" />
                    </div>
                    <div className="content_inner">
                      <p className="post-date"><span className="icon-calendar" />October 8, 2023</p>
                      <h3><a href="blog-single.html">How to Handle Employee</a></h3>
                    </div>
                  </div>
                  <div className="blog_in clearfix image_in">
                    <div className="image">
                      <img decoding="async" src={image} alt="img" />
                    </div>
                    <div className="content_inner">
                      <p className="post-date"><span className="icon-calendar" />October 8, 2023</p>
                      <h3><a href="blog-single.html">Retaining Good Employees &amp; Motivated</a></h3>
                    </div>
                  </div>
                </div>
              </div>
              <div className="widgets_grid_box">
                <h2 className="widget-title">Tag Clouds</h2>
                <div className="wp-block-tag-cloud">
                  <a href="#">Appraisal</a>
                  <a href="#">Business</a>
                  <a href="#">Contract</a>
                  <a href="#">Data</a>
                  <a href="#">Efficiency</a>
                  <a href="#">Employment</a>
                  <a href="#">HR Procedures</a>
                  <a href="#">Legal</a>
                  <a href="#">Management</a>
                  <a href="#">Obligations</a>
                </div>
              </div>
              {/*===============spacing==============*/}
              {/* <div className="pd_bottom_70" /> */}
              {/*===============spacing==============*/}
            </div>
          </aside>
        </div>
      </div>
      {/*-newsteller-*/}
      <section className="newsteller style_one bg_dark_1">
        {/*===============spacing==============*/}
        <div className="pd_top_40" />
        {/*===============spacing==============*/}
        <div className="auto-container pd_top_40">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12">
              <div className="content">
                <h2>Join Our Mailing List</h2>
                <p>For receiving our news and updates in your inbox directly. </p>
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="item_scubscribe">
                <div className="input_group">
                  <form className="mc4wp-form" method="post" data-name="Subscibe">
                    <div className="mc4wp-form-fields">
                      <input type="email" name="EMAIL" placeholder="Your email address" required />
                      <input type="submit" defaultValue="Sign up" />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*===============spacing==============*/}
        <div className="pd_bottom_40" />
        {/*===============spacing==============*/}
      </section>
      {/*-newsteller end-*/}
    </div>
  </div>
  
  {/*-==============mobile menu =================*/}
  <div className="crt_mobile_menu">
    <div className="menu-backdrop" />
    <nav className="menu-box">
      <div className="close-btn"><i className="icon-close" /></div>
      <form role="search" method="get" action="#">
        <input type="search" className="search" placeholder="Search..." defaultValue name="s" title="Search" />
        <button type="submit" className="sch_btn"> <i className="icon-search" /></button>
      </form>
      <div className="menu-outer">
        {/*Here Menu Will Come Automatically Via Javascript / Same Menu as in Header*/}
      </div>
    </nav>
  </div>
  {/*-==============mobile menu end =================*/}
  {/*-==============search popup =================*/}
  <div id="search-popup" className="search-popup">
    <div className="close-search"><i className="fa fa-times" /></div>
    <div className="popup-inner">
      <div className="overlay-layer" />
      <div className="search-form">
        <fieldset>
          <form role="search" method="get" action="#">
            <input type="search" className="search" placeholder="Search..." defaultValue name="s" title="Search" />
            <button type="submit" className="sch_btn"> <i className="icon-search" /></button>
          </form>
        </fieldset>
      </div>
    </div>
  </div>
  {/*-==============search popup end =================*/}
  {/*-==============modal popup =================*/}
  <div className="modal_popup one">
    <div className="modal-popup-inner">
      <div className="close-modal"><i className="fa fa-times" /></div>
      <div className="modal_box">
        <div className="row">
          <div className="col-lg-5 col-md-12 form_inner">
            <div className="form_content">
              <form className="contact-form" method="post" action="https://themepanthers.com/html/creote-html/sendemail.php">
                <p>
                  <label> Your name<br />
                    <input type="text" name="name" defaultValue size={40} aria-required="true" aria-invalid="false" placeholder="Enter Your Name" />
                    <br />
                    <i className="fa fa-user" /><br />
                  </label>
                </p>
                <p><label> Your email<br />
                    <input type="email" name="email" defaultValue size={40} aria-required="true" aria-invalid="false" placeholder="Enter Your Email" />
                    <br />
                    <i className="fa fa-envelope" /><br />
                  </label>
                </p>
                <p>
                  <label> Subject<br />
                    <input type="text" name="subject" defaultValue size={40} aria-required="true" aria-invalid="false" placeholder="Enter Your Subject" />
                    <br />
                    <i className="fa fa-folder" /><br />
                  </label>
                </p>
                <p>
                  <label> Your message (optional)<br />
                    <textarea name="message" cols={40} rows={10} className="wpcf7-form-control wpcf7-textarea" aria-invalid="false" placeholder="Enter Your Message" defaultValue={""} />
                    <br />
                    <i className="fa fa-comments" /><br />
                  </label>
                </p>
                <p><input type="submit" defaultValue="Submit" /></p>
              </form>
            </div>
          </div>
          <div className="col-lg-7 col-md-12 about_company_inner">
            <div className="abt_content">
              <div className="logo">
                <img src="assets/images/logo-default.png" alt="img" className="company_logo_modal" />
              </div>
              <div className="text">
                <p> The great explorer of the truth, the master-builder of human happiness no one rejects
                  dislikes avoids pleasure itself because it is pleasure but because know who do not those
                  how to pursue pleasures rationally encounter consequences that are extremely painful
                  desires to obtain.</p>
                <a href="#">Read More</a>
              </div>
              <div className="post_contet_modal">
                <h2> Latest News</h2>
                <div className="post_enable">
                  <div className="modal_post_grid">
                    <a href="blog-single.html">
                      <img width={852} height={812} src="assets/images/blog/blog-image-9.jpg" className="main_img wp-post-image" alt="img" />
                    </a>
                  </div>
                  <div className="modal_post_grid">
                    <a href="blog-single.html">
                      <img width={852} height={812} src="assets/images/blog/blog-image-8.jpg" className="main_img wp-post-image" alt="img" />
                    </a>
                  </div>
                  <div className="modal_post_grid">
                    <a href="blog-single.html">
                      <img width={852} height={812} src={image} className="main_img wp-post-image" alt="img" />
                    </a>
                  </div>
                  <div className="modal_post_grid">
                    <a href="blog-single.html">
                      <img width={852} height={812} src="assets/images/blog/blog-image-6.jpg" className="main_img wp-post-image" alt="img" />
                    </a>
                  </div>
                  <div className="modal_post_grid">
                    <a href="blog-single.html">
                      <img width={852} height={812} src="assets/images/blog/blog-image-5.jpg" className="main_img wp-post-image" alt="img" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="copright">
                © 2023 Creote. All Rights Reserved.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/*-==============modal popup end=================*/}
  {/*-==============cart=================*/}
  <div className="side_bar_cart" id="mini_cart">
    <div className="cart_overlay" />
    <div className="cart_right_conten">
      <div className="close">
        <div className="close_btn_mini"><i className="icon-close" /></div>
      </div>
      <div className="cart_content_box">
        <div className="contnet_cart_box">
          <div className="widget_shopping_cart_content">
            <p className="woocommerce-mini-cart__empty-message">No products in the cart.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/*-==============cart=================*/}
</div>

  );
}
