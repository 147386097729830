import React from 'react'
import image from '../assets/pexels-pixabay-265072.jpg'

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';


// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

export default function HomeCarousal() {
  return (
    <div style={{height:"90vh"}}>
    <Swiper
    centeredSlides={true}
    loop={true}
    autoplay={{
      delay: 2500,
      disableOnInteraction: false,
    }}
 
   
    modules={[Autoplay, Pagination, Navigation]}
    className="mySwiper"
  >
    <SwiperSlide> <img src={image} className="img-fluid svg_image" alt="icon png" /></SwiperSlide>
    <SwiperSlide> <img src={image} className="img-fluid svg_image" alt="icon png" /></SwiperSlide>
    <SwiperSlide> <img src={image} className="img-fluid svg_image" alt="icon png" /></SwiperSlide>
  </Swiper>
  </div>
  )
}
