import React from 'react'
import { AiOutlineSearch } from "react-icons/ai";
import { MdLogin } from "react-icons/md";
import { Link } from 'react-router-dom';
export default function Navbar({logo}) {
  return (
<nav className="navbar navbar-expand-lg backgroundTrans px-4 py-4">
  <div className="container-fluid d-flex justify-content-between">
 <Link to="/" className="navbar-brand text-white" >{logo ? logo: "logo"}</Link>
    <button className="navbar-toggler text-white" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo01" aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">
    {/* navbar-toggler-icon */}  <span className=" text-white">menu </span> 
    </button>
    <div className="collapse navbar-collapse" id="navbarTogglerDemo01">
      
      <ul className="navbar-nav mx-auto mb-2 text-white gap-4 mb-lg-0"> 
        <li className="nav-item">
         <Link to="/" className="nav-link active text-white fs-5" aria-current="page" >Home</Link>
        </li>
        <li className="nav-item">
         <Link to="/aboutus" className="nav-link active text-white fs-5" aria-current="page" >About Us</Link>
        </li>
        <li className="nav-item">
         <Link to="/blog" className="nav-link active text-white fs-5" aria-current="page" >Blog</Link>
        </li>
        <li className="nav-item">
         <Link to="/service" className="nav-link active text-white fs-5" aria-current="page" >Service</Link>
        </li>
        <li className="nav-item">
         <Link to="/contact" className="nav-link active text-white fs-5"  aria-current="page" >Contact</Link>
        </li>
      </ul>
      <form className="d-flex" role="search">
        <button className='fs-4 border-0 text-white bg-transparent '><AiOutlineSearch/></button>
        <button className='fs-4 border-0 text-white bg-transparent'><MdLogin/></button>
      </form>
    </div>
  </div>
</nav>


  )
}
