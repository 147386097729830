import assets from "../assets/asse";

const blogPostApi = [
    {
        id: 1,
        title: `ASTITVA`,
        desc: `In a world driven by technology, innovation has the power to transform lives and empower 
        communities. Inodayam Tech Pvt. Ltd., we believe in harnessing the potential of technology to drive 
        positive change and create a brighter future for all. Operating under the brand name Astitva, we are 
        a multifaceted company dedicated to empowering various sectors of society through innovative 
        technology solutions.
        Astitva is more than just a company – it is a symbol of resilience, identity, and potential. With a 
        diverse portfolio of products and services, we strive to make a meaningful impact across 
        different domains, from education and healthcare to agriculture and beyond. let us harness the 
        power of technology to create a brighter, more inclusive future for all. `,
        mission: `At Inodayam Tech Pvt. Ltd., operating under the brand name Astitva, our mission is to 
        harness the power of innovative technology solutions to empower and uplift various sectors 
        of society. With a commitment to creating positive impact and driving meaningful change, 
        we aim to leverage our diverse portfolio of products and services to address key challenges 
        and unlock new opportunities across different domains. `,
        vision: `our vision is to create a more equitable, sustainable, and prosperous world for present and future 
        generations. Through our relentless pursuit of excellence and our unwavering commitment to social 
        impact, we aspire to leave a legacy of positive change that transcends borders, cultures, and 
        generations.`,
        overview:``,
        links: `l`,
        images: `${process.env.REACT_APP_BLOGINSIDEASTITVA}`
    },
    {
        id: 2,
        title: `ADS GRAM`,
        desc: `In the fast-paced world of marketing and advertising, standing out from the crowd is essential for 
        success. Ads gram – a groundbreaking portal committed to redefining the landscape of marketing 
        and advertisement through innovation, creativity, and expertise. With a mission to revolutionize the 
        industry, Ads gram channels the power of cutting-edge technology to deliver immersive and 
        impactful advertising experiences like never before.
        we believe that creativity knows no bounds, and innovation is the key to unlocking endless 
        possibilities. Our team of experts is dedicated to pushing the boundaries of traditional advertising by 
        harnessing the latest advancements in 2D and 3D billboard technology, as well as pioneering 
        holographic displays. 
        So, Whether you are a brand looking to make a splash in the market or an advertiser seeking 
        innovative ways to connect with your audience, Ads gram has the expertise, creativity, and 
        technology to bring your vision to life.
        Experience the power of innovation and creativity – only with Ads gram. `,
        mission: `Through our innovative approach and unwavering commitment to excellence, we strive to elevate 
        brands, engage audiences, and transform the way the world perceives advertising. At Ads Gram, we 
        do not just create advertisements, we craft experiences that inspire, inform, and ignite imaginations. 
        Our aim is to shape the future of marketing. `,
        vision: `At Ads Gram, our vision is to Reach the Top of the Marketing field in innovative marketing and 
        advertising solutions, setting the standard for creativity, effectiveness, and impact. We envision a 
        future where every advertisement is not just seen but experienced, where 2D and 3D billboards and 
        holograms are the norm rather than the exception. 
        As we work towards our vision, we remain committed to delivering exceptional value to our clients, 
        empowering them to achieve their marketing goals and elevate their brands to new heights. With 
        Ads Gram, The Future Of Advertising Is Not Just Bright—It is Holographic. `,
        overview:` Our Ads Gram platform is designed for advertisement and promotion, offering businesses 
        effective tools to reach their target audience and enhance brand visibility in the digital space. To leave 
        a lasting impression on people we leverage 3D Billboards and Hologram. Ads Gram prioritizes user 
        engagement and interaction, providing businesses with the tools they need to connect with their 
        audience on a deeper level. With our platform, businesses can stay ahead of the competition and stand 
        out in the crowded digital landscape.`,
        links: `l`,
        images: `${process.env.REACT_APP_BLOGINSIDEADSGRAM}`
    },
    {
        id: 3,
        title: `MY NETA`,
        desc: `In this dynamic world of politics, transparency, accountability, and fairness are the cornerstones of a 
        healthy democracy. Yet, navigating the electoral process can be daunting, especially for candidates 
        with limited resources and support. My Neta – a dedicated app focused on promoting transparency, 
        accountability, and fairness in the electoral process, empowering candidates to effectively 
        communicate their message and engage with voters on issues that matter most.
        we believe in levelling the playing field for candidates of all backgrounds and ideologies. Our 
        platform serves as a strategic partner for candidates, offering tailored guidance and support 
        throughout the campaign process. From strategic planning and message development to targeted 
        outreach and data analytics, My Neta provides candidates with the insights and resources they need 
        to run successful and impactful election campaigns.
        Experience the power of democracy in action – only with My Neta.`,
        mission: `My Neta is a pivotal component of Ads Gram, our mission is to revolutionize election promotion and 
        political marketing by providing candidates with a comprehensive platform that serves as both a 
        marketing tool and strategic planner. We are committed to leveraging innovative technology and 
        strategic insights to empower candidates to effectively reach and connect with voters, ultimately 
        driving positive change and fostering democratic participation. 
        Our primary objective is to equip candidates with the tools and guidance they need to navigate the 
        complexities of election campaigns successfully. Through our app, candidates gain access to a wide 
        range of marketing resources, including strategic planning tools, campaign management features, 
        and data analytics insights, all tailored to their specific needs and objectives. `,
        vision: `Our vision is to democratize election promotion, ensuring that candidates of all backgrounds have 
        access to the tools, resources, and support they need to effectively communicate their message and 
        engage with voters. By leveraging technology and innovation, My Neta aims to strengthen 
        democratic governance and promote civic participation, ultimately building a more inclusive and 
        representative democracy for all. 
        we are committed to promoting transparency, fairness, and ethical conduct in the electoral process. 
        Through our platform, we aim to empower candidates to connect with voters authentically and 
        meaningfully, fostering trust and accountability in the political system. `,
        overview:` My Neta is our election campaign platform, providing comprehensive solutions for political 
        candidates to strategize, manage, and execute their election campaigns efficiently. We prioritize 
        security and data privacy to safeguard sensitive campaign information and maintain trust with voters. 
        My Neta is more than just a campaign platform; it's a strategic partner for candidates looking to win 
        elections and make a difference in their communities.`,
        links: `l`,
        images: `${process.env.REACT_APP_BLOGINSIDEMYNETA}`
    },
    {
        id: 4,
        title: `CLUB CARE`,
        desc: `n the bustling tapestry of society, a sense of community is the cornerstone of harmony and 
        wellbeing. Imagine a platform where neighbours come together to celebrate festivals, organize 
        events, and ensure the safety and security of their community – all while accessing essential services 
        with ease. 
        Welcome to Club Care – your dedicated portal for societal e-commerce and safety, where community 
        engagement meets convenience and peace of mind. Whether you are celebrating festivals, 
        accessing essential services, or fostering connections with your neighbours, Club Care is your trusted 
        companion every step of the way. Download the app today and experience the power of community
        driven e-commerce and safety firsthand. 
        let us create a safer, stronger, and more connected community for all.`,
        mission: `Our mission is to empower residential societies to cultivate vibrant, connected communities through 
        our innovative app platform. We are committed to providing a comprehensive and user-friendly 
        solution that enables society members to stay connected with each other while also accessing 
        essential services and resources conveniently.
        We are ambitious to make life easier and more enjoyable for residents of residential societies by 
        providing them with a trusted and convenient platform to stay connected and access essential 
        services. With Club care, we aspire to create communities where neighbours become friends, and 
        every member feels valued and supported
        `,
        vision: `Our primary objective is to create a centralized platform that facilitates seamless communication and 
        engagement among society members. Through Club care, residents can easily connect with their 
        neighbours, share information, organize events, and collaborate on community initiatives, fostering a 
        sense of belonging and camaraderie within the neighbourhood.
        Club care aspire to build stronger, more resilient neighbourhoods that serve as models of community 
        living for generations to come. `,
        overview:` Club Care serves as a residential society e-commerce portal and security app, offering 
        essential services and fostering community engagement within residential communities. We foster 
        community engagement by facilitating communication and collaboration among residents through 
        forums, events, and group activities. Club Cure is committed to enhancing the quality of life within 
        residential societies by promoting a sense of belonging and shared responsibility`,
        links: `l`,
        images: `${process.env.REACT_APP_BLOGINSIDECLUBCARE}`
    },
    {
        id: 5,
        title: `ASTITVA REALITY`,
        desc: `n the ever-evolving landscape of real estate, finding the perfect property can often feel like searching 
        for a needle in a haystack. Whether you are in pursuit of your dream home, looking to invest in lucrative 
        commercial spaces, or seeking the ideal rental property, navigating the complexities of the real estate 
        market can be daunting. That is where Astitva Reality steps in – your dedicated portal for buying, 
        selling, and renting properties with ease and confidence.
        Astitva Reality is not just another real estate platform; it is your trusted partner in every step of your 
        property journey. With a commitment to transparency, reliability, and customer satisfaction, Astitva 
        Reality sets the gold standard for excellence in the real estate industry. Whether you are embarking 
        on a quest to find your dream home, seeking lucrative investment opportunities, or looking to list your 
        property for rent or sale, Astitva Reality is your trusted ally in the dynamic world of real estate`,
        mission: `Our mission is to revolutionize the property search experience by providing a comprehensive and 
        user-friendly platform that empowers individuals to find their ideal properties with ease and 
        confidence. We are committed to leveraging technology and innovation to simplify the real estate 
        journey, ensuring that every user can discover a home that truly reflects their unique aspirations and 
        lifestyle. 
        As Property advisor our primary objective is to create a trusted and transparent marketplace where 
        buyers, sellers, renters, and agents can connect seamlessly. Through our intuitive app, users gain 
        access to an extensive database of properties, along with powerful search filters and personalized 
        recommendations, allowing them to explore a diverse range of options tailored to their preferences 
        and needs. `,
        vision: `We envision Astitva Reality as the go-to destination for anyone looking to buy, sell, or rent 
        properties, offering a comprehensive and user-friendly platform that caters to the diverse needs and 
        preferences of our users. Through our advanced search algorithms, personalized recommendations, 
        and extensive database of properties, we strive to provide a seamless and intuitive property search 
        experience that exceeds our user’s expectations. 
        our motive is to empower individuals to find their place in the world—a home that reflects their 
        unique identity, aspirations, and lifestyle. By redefining the property search experience and creating 
        opportunities for connection and discovery, we aim to enrich lives and build stronger communities, 
        one property at a time. `,
        overview:` Astitva Reality is our online property portal, offering a curated selection of properties 
        for buyers, sellers, and renters, along with expert guidance and support throughout the real estate 
        transaction process. We prioritize transparency and reliability, so you can trust the information 
        provided on our platform. From residential to commercial properties, we have something for 
        everyone, backed by our dedication to exceptional service and customer satisfaction`,
        links: `l`,
        images:`${process.env.REACT_APP_BLOGINSIDEASTITVAREALITY}`
    },
    {
        id: 6,
        title: `ASTITVA MART`,
        desc: `In the bustling landscape of grocery shopping, one often encounters a disconnect between the 
        farmer who cultivates the produce and the consumer who purchases it. With each link in the supply 
        chain, prices inflate, and quality diminishes, leaving both farmers and consumers at a disadvantage. 
        But what if there was a way to break this cycle and create a direct bridge between farmers and 
        consumers? Enter Astitva Mart – a revolutionary platform dedicated to bringing groceries and 
        vegetables directly from the farmer to your doorstep, all while breaking the chains of intermediaries 
        and ensuring affordability and freshness like never before.
        At Astitva Mart, we believe in the power of connection – connection between the hands that sow 
        the seeds and the mouths that Savor the harvest. Our mission is simple yet transformative: to 
        empower farmers by providing them with a direct platform to sell their produce and to empower 
        consumers by offering them access to affordable, fresh, and high-quality groceries and vegetables. 
        our motive is to rediscover the true essence of grocery shopping at Astitva Mart. Together, let us 
        break the chains of intermediaries, support local farmers, and create a brighter, more sustainable 
        future for all. Experience the magic of direct-from-farm freshness and affordability – only at Astitva 
        Mart.`,
        mission: `At Astitva Mart, our mission is to redefine the online shopping experience by providing a diverse 
        range of high-quality products that cater to the unique needs and preferences of our customers. We 
        are committed to creating a seamless and enjoyable shopping platform that offers convenience, 
        reliability, and affordability, while also fostering a sense of community and empowerment among 
        our users.
        Our Prime Motive is to empower individuals to express their unique style and identity through the 
        products they choose. By offering a diverse and curated selection of products, backed by exceptional 
        customer service and a commitment to social responsibility, Astitva Mart aims to enrich lives and 
        inspire confidence in every customer we serve`,
        vision: `Astitva Mart envisions becoming the leading online shopping destination globally, renowned for its 
        curated selection of products, exceptional customer service, and commitment to social 
        responsibility. We aspire to redefine the online shopping experience by creating a platform that not 
        only offers convenience and reliability but also fosters a sense of connection, empowerment, and 
        inspiration among our customers.
        we see Astitva Mart as a catalyst for positive change in the retail industry, driving innovation, 
        sustainability, and social impact. By forging partnerships with local artisans, small businesses, and 
        ethical suppliers, we aim to support economic empowerment, promote sustainable practices, and 
        contribute to the well-being of communities Nation-wide.`,
        overview:` Astitva Mart is our online shopping platform, offering a diverse range of high-quality 
        products and services to customers, with a focus on convenience, reliability, and affordability. We 
        understand the value of your time and money, so we strive to make your shopping experience 
        efficient and cost-effective. Whether you're browsing for everyday essentials or indulging in 
        something special, Astitva Mart is here to meet your needs with unmatched variety and value.
        `,
        links: `l`,
        images: `${process.env.REACT_APP_BLOGINSIDEASTITVAMART}`
    },
    {
        id: 7,
        title: `DIGI GRAM`,
        desc: `In the heart of rural India lies a wealth of untapped potential and boundless opportunities. Yet, for 
        too long, rural communities have been disconnected from the digital revolution sweeping the 
        nation. Enter Digi Gram – a revolutionary platform dedicated to bridging the digital divide and 
        empowering rural sectors by providing access to essential resources, services, and information. Join 
        us in ushering rural India into the digital age with Digi Gram. Together, let us empower communities, 
        unlock potential, and build a brighter future for all. Download the app today and embark on a 
        journey of digital transformation and prosperity. With Digi Gram, the possibilities are endless, and 
        the future is bright for rural India.`,
        mission: `At Digi Gram, our mission is to empower rural communities by providing them with a comprehensive 
        digital platform that serves as a one-stop solution for all their needs. We are committed to leveraging 
        technology to enhance the quality of life in villages by providing access to essential information, 
        services, and resources through our user-friendly app.
        we believe in fostering economic development in rural areas by facilitating e-commerce 
        opportunities through our platform. By connecting villagers with local shops, medical facilities, and 
        agricultural suppliers, Digi Gram enables them to access essential goods and services conveniently 
        and affordably, thereby stimulating local economies and creating employment opportunities.
        our mission is to harness the power of technology to bridge the digital divide and empower rural 
        communities to thrive in the digital age. By providing access to essential information, services, and 
        opportunities, Digi Gram aims to create a more inclusive and prosperous future for all.`,
        vision: `We envision a future where every village is connected through Digi Gram, providing residents with 
        easy access to comprehensive data and resources about their community. From demographic 
        information to agricultural insights, water resource management, weather forecasts, and 
        ecommerce facilities, Digi Gram will serve as the central hub for all village-related needs.
        As we look towards the Future, our vision is for Digi Gram to be synonymous with rural 
        empowerment and sustainable development worldwide. By leveraging technology to bridge the 
        digital divide and empower rural communities, Digi Gram will play a pivotal role in building a more 
        inclusive, resilient, and prosperous future for all.`,
        overview:` Digi Gram is dedicated to empowering digital and organic farming practices, providing 
        farmers with tools and resources to optimize crop production and sustainable agricultural practices.
        This innovative platform provides valuable resources such as organic farming knowledge and digital 
        copies of important documents like Aadhar cards and PAN cards.Digi Gram, rural residents can engage in local e-commerce and take advantage of digital 
        advancements. Farmers can stay informed about crop prices and make more informed decisions 
        about their agricultural activities.`,
        links: `l`,
        images: `${process.env.REACT_APP_BLOGINSIDEDIGIGRAM}`
    },
    {
        id: 8,
        title: `VED-GURUKUL`,
        desc: `In a world where technology continues to reshape the way we live, work, and learn, the traditional 
        education system is ripe for innovation. Enter Ved Gurukul – an innovative app dedicated to pioneering 
        a bagless education system that harnesses the power of digital technology to redefine the learning 
        experience. Here, parents can also stay actively involved in their child's education journey like never 
        before. Our app provides real-time updates and insights into your child's academic performance, 
        attendance, and overall progress. 
        Whether you are at home or travelling you can track your child's milestones, monitor their 
        achievements, and identify areas for improvement with ease. We understand the importance of 
        privacy and security when it comes to your child's information. That is why Ved Gurukul prioritizes data 
        protection and encryption to ensure that your child's personal information remains safe and secure at 
        all times . You can trust that your child's data is in good hands with Ved Gurukul. 
        Reimagine education for the digital age with Ved Gurukul.
        `,
        mission: `Our mission is to revolutionize education by pioneering a digital learning ecosystem where bag-less 
        schooling is a priority. We are dedicated to transforming traditional classrooms into dynamic, 
        engaging environments by providing schools with state-of-the-art technology, including tablets and 
        digital boards, to make learning both interesting and digital. `,
        vision: `Our vision for Ved Gurukul by 2030 is to empower individuals to realize their full potential and 
        contribute meaningfully to society in the digital age. Together, we are building a future where 
        education knows no bounds, and every learner can thrive and succeed in a rapidly changing world. 
        We envision a world where traditional school bags are relics of the past, replaced by sleek tablets 
        and interactive digital boards in every classroom. Our commitment to providing schools with the 
        latest technology will continue to redefine the learning experience, making it not only more efficient 
        but also more enjoyable and meaningful for students. `,
        overview:`: Ved Gurukul is our digital education platform, committed to revolutionizing the 
        education sector by promoting a bag-less education culture and providing digital learning solutions 
        to students and educators of Rural Areas. This app will give all the necessary details of the child to 
        parents from progress to fess, assessment to Improvement everything will be available at the 
        fingertips of the parents.`,
        links: `l`,
        images: `${process.env.REACT_APP_BLOGINSIDEVEDGURUKUL}`
    },
    {
        id: 9,
        title: `KRISHI FARM:`,
        desc: `In the vast expanse of agricultural endeavours, farmers are the unsung heroes, toiling tirelessly to 
        feed nations and sustain livelihoods. At Krishi Farm, we stand as a steadfast partner to farmers, 
        offering unwavering support at every step of their journey towards greater productivity, profitability, 
        and sustainability.
        Krishi Farm is more than just a portal – let’s illuminate the hope and empowerment for farmers 
        across the Nation. With a deep-rooted commitment to agricultural excellence, we provide farmers 
        with the tools, resources, and expertise they need to thrive in an ever-changing landscape.
        `,
        mission: `Our mission is to empower farmers with the tools, knowledge, and resources they need to protect 
        and enhance agricultural production sustainably. We are dedicated to leveraging technology to 
        revolutionize farming practices, ensuring the prosperity and resilience of farming communities 
        worldwide. 
        Our commitment extends beyond mere assistance; we aim to foster a holistic ecosystem that 
        addresses the diverse needs of farmers. Through our comprehensive app, we provide timely and 
        personalized guidance on crop management, pest control, soil health, and more, tailored to the 
        specific conditions of each farm.
        `,
        vision: `Our vision is to revolutionize agriculture through innovation and empowerment, creating a future 
        where farmers have the tools and knowledge to protect their crops and increase production 
        sustainably. 
        We envision a world where every farmer, regardless of location or resources, has access to leading 
        edge technology and expert guidance at their fingertips. Through our app, farmers will be 
        empowered to make informed decisions, optimize resources, and mitigate risks, resulting in higher 
        yields and greater profitability.`,
        overview:`Kisan Farm is an app designed to empower individual farmers, providing them with 
        resources and support to grow crops and businesses effectively; while fostering community 
        collaboration and knowledge sharing. Farmers receive personalized guidance and support. 
        Whether selecting the right crops, managing pests and diseases, or implementing sustainable 
        farming practices, Krishi Farm provides expert advice and resources to ensure success. `,
        links: `l`,
        images: `${process.env.REACT_APP_BLOGINSIDEKRISHIFARM}`
    },
    {
        id: 10,
        title: `FILMY ADDA`,
        desc: `Are you an aspiring actor, filmmaker, or artist dreaming of making it big in the Indian film industry? 
        Look no further than Filmy Adda – your ultimate portal to showcase your talent and unlock 
        opportunities in the captivating world of cinema.
        In the bustling realm of showbiz, where talent meets opportunity, Filmy Adda stands out as a beacon 
        for emerging artists eager to carve their niche in the glamorous world of Indian cinema. so, if, you 
        are a budding actor, director, screenwriter, cinematographer, or any other creative professional, 
        Filmy Adda provides the perfect platform to showcase your skills and connect with industry insiders.
        Filmy Adda welcomes you with open arms. Join us today and embark on an exhilarating journey to 
        turn your dreams into reality in the vibrant world of Indian cinema.
        `,
        mission: `our mission is to empower aspiring actors, directors, singers, and other creative talents by providing 
        them with a platform to showcase their skills and connect with established professionals in the 
        entertainment industry. We are dedicated to democratizing access to opportunities and fostering a 
        vibrant community where talent meets opportunity.
        Together, we are building a community where creativity thrives, opportunities abound, and dreams 
        become reality. Join us on this journey as we empower the next generation of entertainment 
        industry leaders and redefine the future of talent discovery and collaboration.
        `,
        vision: `At Filmy Add, our vision is to revolutionize the entertainment industry by becoming the premier 
        platform for aspiring actors, directors, singers, and other creative talents to showcase their abilities 
        and connect with established professionals. We envision a future where talent discovery is 
        democratized, and opportunities are accessible to all, regardless of background or location.
        Ultimately, our vision for Filmy Adda.com is to empower aspiring artists to realize their full potential 
        and achieve success in the entertainment industry. Whether it is landing their first role, directing their 
        debut film, or releasing their debut album, we aspire to be the launchpad for countless dreams and 
        aspirations.`,
        overview:` Filmy Adda.com is a platform dedicated to providing opportunities for aspiring actors, 
        directors, singers, and other talents in the film industry to showcase their skills and connect with 
        industry professionals. This dynamic portal serves as a hub where creativity meets opportunity, 
        offering a platform for talented individuals to showcase their skills and break into the Indian film 
        industry. From actors to directors, writers to musicians, Filmy Adda welcomes all with open arms, 
        providing a gateway to the glitz and glamour of Bollywood and beyond.`,
        links: `l`,
        images: `${process.env.REACT_APP_BLOGINSIDEFILMYADDA}`
    }
    ];
    
    export default blogPostApi;
    