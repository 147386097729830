import React, { useEffect, useState } from 'react'
import image from '../assets/pexels-pixabay-265072.jpg'
import AOS from 'aos';
import 'aos/dist/aos.css';
import { AiOutlinePlus } from "react-icons/ai";
export default function HomeProject() {
    const [selectedCategory, setSelectedCategory] = useState("*");


  
    const handleFilter = (category) => {
        setSelectedCategory(category);
    };
  
    const projects = [
        { title: "Complex Dismissal for a Small Company", category: "coaching" },
        { title: "Essential Steps to Writing Job Description", category: "human-resources" },
        { title: "Company Values & The Relationship", category: "leadership" },
        { title: "Time HR Prepares Plastic Contract Manufacturer", category: "pre-sale" },
        { title: "Shared Time Human Resources Management", category: "recruiting" },
        { title: "Six Essential Steps To Writing Successful Job", category: "values" }
    ];
  
    const filteredProjects = selectedCategory === "*" ? projects : projects.filter(project => project.category === selectedCategory);
  
  
  return (
   
<section className="project-section bg_light_1">
  {/*===============spacing==============*/}
  <div className="pd_top_80" />
  {/*===============spacing==============*/}
  <div className="container">
    <div className="row">
      <div className="col-lg-12">
        <div className="title_all_box style_one text-center dark_color">
          <div className="title_sections mb-4">
            <div className="before_title">Incredibly</div>
            <h2>Effective Case Studies</h2>
          </div>
          {/*===============spacing==============*/}
          <div className="" />
          {/*===============spacing==============*/}
        </div>
      </div>
    </div>
    <div className="project_all filt_style_six filter_enabled mt-5">
            <div className="row">
                <div className="col-lg-12">
                    <div className="fliter_group" style={{textAlign: 'center !important'}}>
                        <ul className="project_filter dark clearfix">
                            <li onClick={() => handleFilter("*")} className={selectedCategory === "*" ? "current" : ""}>View All</li>
                            <li onClick={() => handleFilter("coaching")} className={selectedCategory === "coaching" ? "current" : ""}>Coaching</li>
                            <li onClick={() => handleFilter("human-resources")} className={selectedCategory === "human-resources" ? "current" : ""}>Human Resources</li>
                            <li onClick={() => handleFilter("leadership")} className={selectedCategory === "leadership" ? "current" : ""}>Leadership</li>
                            <li onClick={() => handleFilter("pre-sale")} className={selectedCategory === "pre-sale" ? "current" : ""}>Pre sale</li>
                            <li onClick={() => handleFilter("recruiting")} className={selectedCategory === "recruiting" ? "current" : ""}>Recruiting</li>
                            <li onClick={() => handleFilter("values")} className={selectedCategory === "values" ? "current" : ""}>Values</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="project_container mt-3 clearfix" style={{position: 'relative'}}>
                <div className="row clearfix">
                    {filteredProjects.map((project, index) => (
                        <div key={index} className={`project-wrapper px-3 grid-item col-xl-4 col-lg-6 col-md-6 col-sm-12 col-xs-12 project_category-${project.category}`}>
                            <div className="project_post style_six">
                                <div className="image_box">
                                    <img width={746} height={497} src={image} className="attachment-creote_project_caro_image_style_one size-creote_project_caro_image_style_one wp-post-image" alt="img" />
                                    <div className="overlay">
                                        <div className="content_box">
                                            <h2 className="title_pro"><a href="#" rel="bookmark">{project.title}</a></h2>
                                            <p>{project.category}</p>
                                        </div>
                                    </div>
                                </div>
                                <a data-fancybox="gallery"  href="https://images.pexels.com/photos/217114/pexels-photo-217114.jpeg?auto=compress&cs=tinysrgb&w=600">
                                    <AiOutlinePlus className="icon-add p-2 zoom_icon" />
                                </a>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
  </div>
  {/*===============spacing==============*/}
  <div className="pd_bottom_60" />
  {/*===============spacing==============*/}
</section>
  )
}
