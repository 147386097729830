import React, { useEffect } from 'react'
import '../Style.css';
import '../Theme.css';
import image from '../assets/pexels-pixabay-265072.jpg'
import { MdOutlineHeadsetMic } from "react-icons/md";
import HomeCarousal from '../Components/HomeCarousal.js';
import HomeProject from '../Components/HomeProject.js';
import HomeAbout from '../Components/HomeAbout.js';
import HomeService from '../Components/HomeService.js';
import HomeProcess from '../Components/HomeProcess.js';
import HomeClient from '../Components/HomeClient.js';
import HomeTeamSection from '../Components/HomeTeamSection.js';
import HomeTestimonial from '../Components/HomeTestimonial.js';
import HomeBlogSection from '../Components/HomeBlogSection.js';

export default function Home() {
  
  useEffect (()=>{
    window.scrollTo(0, 0);
   },[])
 
  return (
   <div className='HomeMain container-fluid allmain px-0'>
   
    <HomeCarousal/>

    <div className='container d-flex flex-wrap justify-content-center gap-3 pb-5  col-12'>

    <div className="row col-xl-3 col-lg-8 col-md-12 mb-md-3 mb-sm-3 col-sm-12  ">
  <div className="py-2 px-3 bg-white rounded-3 " style={{boxShadow:"0px 15px 30px -12px"}}>
    <div className="icon_content ">
      <div className=" rowIcon d-flex justify-content-between mb-3">
        <span><MdOutlineHeadsetMic/></span> <div className='rowIconNumber'>01</div>
      </div>
      
      <div className="fs-6 ">
        <h4>24/7 Customer support</h4>
        <p className='m-0'>The less water you use, the less runoff and wastewater that eventually end
          up in the ocean.</p>
      </div>
    </div>
  </div>
</div>

<div className="row col-xl-3 col-lg-8 col-md-12 mb-md-3 mb-sm-3 col-sm-12 ">
  <div className="py-2 px-3 bg-white rounded-3 " style={{boxShadow:"0px 15px 30px -12px"}}>
    <div className="icon_content ">
      <div className=" rowIcon d-flex justify-content-between mb-3">
        <span><MdOutlineHeadsetMic/></span> <div className='rowIconNumber'>01</div>
      </div>
      
      <div className="fs-6 ">
        <h4>24/7 Customer support</h4>
        <p className='m-0'>The less water you use, the less runoff and wastewater that eventually end
          up in the ocean.</p>
      </div>
    </div>
  </div>
</div>

<div className="row col-xl-3 col-lg-8 col-md-12 mb-md-3 mb-sm-3 col-sm-12  ">
  <div className="py-2 px-3 bg-white rounded-3 " style={{boxShadow:"0px 15px 30px -12px"}}>
    <div className="icon_content ">
      <div className=" rowIcon d-flex justify-content-between mb-3">
        <span><MdOutlineHeadsetMic/></span> <div className='rowIconNumber'>01</div>
      </div>
      
      <div className="fs-6 ">
        <h4>24/7 Customer support</h4>
        <p className='m-0'>The less water you use, the less runoff and wastewater that eventually end
          up in the ocean.</p>
      </div>
    </div>
  </div>
</div>

</div>

{/*--about--*/}
<HomeAbout/>


{/*--service--*/}
<HomeService/>


{/*--process--*/}
<HomeProcess/>


<HomeProject/>




{/*--contact-client */}
<HomeClient/>

{/*--team-section */}
<HomeTeamSection/>



{/*-testimonial--*/}
<HomeTestimonial/>



{/*--blog-section*/}
<HomeBlogSection/>



   </div>
  )
}
