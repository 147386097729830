import React from 'react'
import image from '../assets/pexels-pixabay-265072.jpg'

import { AiFillSkype } from "react-icons/ai";
import home12 from '../assets/home-12-testi.jpg'
import { HiOutlinePlay } from "react-icons/hi2";
import { BiLogoWhatsapp } from "react-icons/bi";
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';


// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
export default function HomeClient() {
  return (
   
<section className="contact-client-carousel-section" >
            <div className="row">
                <div className="col-xxl-5 col-xl-5 col-lg-12 bg_op_1" style={{background: `url(${image})`}}>
                    <div className="videobtns text-center pd_right_100 pd_left_100 pd_top_100 pd_bottom_100">
                        {/* ===============spacing=============== */}
                        <div className="pd_top_80"></div>
                        <div className="pd_top_10"></div>
                        {/* ===============spacing=============== */}
                        <div className="video_btn_all">
                            <div className="video_box">
                                <a href="https://www.youtube.com/embed/-VK0axfge4A" className="lightbox-image color_white">
                                  <span className="icon-play"> <HiOutlinePlay className="fs-1 mb-2 " /></span> 
                                </a>
                            </div>
                            {/* ===============spacing=============== */}
                            <div className="pd_bottom_30"></div>
                            {/* ===============spacing=============== */}
                        </div>
                        <h2 className="color_white">Just Watch Our Work Process</h2>
                        {/* ===============spacing=============== */}
                        <div className="pd_top_100"></div>
                        {/* ===============spacing=============== */}
                    </div>
                </div>
                <div className="col-xl-7 col-lg-7 col-md-12 bg_op_1"
                    style={{backgroundImage: `url(${home12})`}}>
                    {/* ===============spacing=============== */}
                    <div className="pd_top_90"></div>
                    {/* ===============spacing=============== */}
                    <div className="row px-2">
                        <div className="col-lg-1"></div>
                        <div className="col-lg-9 col-md-12">
                            <div className="client-brand-wrapper">
                                <div className="title_all_box style_one light_color">
                                    <div className="title_sections left">
                                        <h2>Creote has extensive experience across all industries. </h2>
                                        <p>Over 20 years of experience we’ll ensure you always get the best guidance.We help
                                            our clients set new standards of excellence.</p>
                                    </div>
                                </div>
                                {/* ===============spacing=============== */}
                                <div className="pd_bottom_15"></div>
                                {/* ===============spacing=============== */}
                                <div className="client_logo_carousel type_two mb-4">
                                <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        breakpoints={{
          1200: {
            slidesPerView: 4
          },
          1024: {
            slidesPerView: 4
          },
          768: {
            slidesPerView: 4
          },
          576: {
            slidesPerView: 3
          },
          250: {
            slidesPerView: 2
          },
          0: {
            slidesPerView: 1
          }
        }}
       
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
      >
        <SwiperSlide>              <img src={image} className="img-fluid svg_image" alt="icon png" /></SwiperSlide>
        <SwiperSlide>              <img src={image} className="img-fluid svg_image" alt="icon png" /></SwiperSlide>
        <SwiperSlide>              <img src={image} className="img-fluid svg_image" alt="icon png" /></SwiperSlide>
        <SwiperSlide>              <img src={image} className="img-fluid svg_image" alt="icon png" /></SwiperSlide>
        <SwiperSlide>              <img src={image} className="img-fluid svg_image" alt="icon png" /></SwiperSlide>
        <SwiperSlide>              <img src={image} className="img-fluid svg_image" alt="icon png" /></SwiperSlide>
        <SwiperSlide>              <img src={image} className="img-fluid svg_image" alt="icon png" /></SwiperSlide>
        <SwiperSlide>              <img src={image} className="img-fluid svg_image" alt="icon png" /></SwiperSlide>
        <SwiperSlide>              <img src={image} className="img-fluid svg_image" alt="icon png" /></SwiperSlide>
        </Swiper>
                                </div>
                                {/* ===============spacing=============== */}
                                <div className="pd_bottom_40"></div>
                                {/* ===============spacing=============== */}
                                <div className="row gutter_20px ">
                                    <div className="col-lg-5 col-md-12">
                                        <div className="contact_box_content style_two">
                                            <div className="contact_box_inner color_two">
                                                <div className="icon_bx ">
                                                   <span className='fa fa-whatsapp '> <BiLogoWhatsapp className="mb-1 fs-2"/></span>
                                                </div>
                                                <div className="text ">
                                                    <h3>What’s App Chat</h3>
                                                    <p>(888)456 - 7890 - 12354</p>
                                                </div>
                                            </div>
                                        </div>
                                        {/* ===============spacing=============== */}
                                        <div className="pd_bottom_20"></div>
                                        {/* ===============spacing=============== */}
                                    </div>
                                    <div className="col-lg-5 col-md-12">
                                        <div className="contact_box_content  style_two">
                                            <div className="contact_box_inner color_two">
                                                <div className="icon_bx">
                                                    <span className="fa fa-skype"><AiFillSkype className=' mb-1 fs-2'/></span>
                                                </div>
                                                <div className="text ">
                                                    <h3>Skype Meet</h3>
                                                    <p>creotesuppot@gmail.com</p>
                                                </div>
                                            </div>
                                        </div>
                                        {/* ===============spacing=============== */}
                                        <div className="pd_bottom_20"></div>
                                        {/* ===============spacing=============== */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2"></div>
                    </div>
                    {/* ===============spacing=============== */}
                    <div className="pd_bottom_60"></div>
                    {/* ===============spacing=============== */}
                </div>
            </div>
        </section>
  )
}
