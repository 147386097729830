import React, { useEffect } from 'react'
import assets from '../assets/asse'
import Aos from 'aos'
import 'aos/dist/aos.css'
export default function AboutUsAdsgram() {
   useEffect(()=>{
        Aos.init({duration:1000})
   },[])
  return (
    <div className='col-12 col-lg-9 col-xl-12' style={{overflow:'hidden'}}>
        <div className=' '>
            <div className='inodayamadsgramReverse  d-flex flex-wrap '>
                <div className='inodayamadsgram  d-flex align-items-center col-12 col-lg-6 col-xl-6 border-end border-black'>
                    <div data-aos="fade-right" className='container inodayamadsgram1 px-4'>
                    <h3 className='pb-2'>Dynamic Ad Placement:</h3>
                    <p>AdsGram offers unparalleled flexibility in ad placement, allowing advertisers to tailor their campaigns to suit specific demographics, locations, and objectives. Whether it's targeting a local audience with an LED billboard in a bustling city center, engaging social media users with eye-catching content, or creating a memorable holographic experience at a high-traffic event, AdsGram ensures that each ad reaches its intended audience with precision and impact.</p>
               
                    </div>
                    </div>
                <div className='col-12 col-lg-6 col-xl-6 py-4'>
                    <div data-aos="fade-left" className='container px-4'>
                        <img src={process.env.REACT_APP_ABOUTUSIMAGEADSGRAMDYNAMICADPLACEMENT}/>
                    </div>
                </div>
            </div>


            <div className='inodayamadsgramReverse d-flex flex-wrap'>
                <div className='inodayamadsgram col-12 col-lg-6 col-xl-6 border-end border-black py-4'>
                <div data-aos="fade-right" className='container px-4'>
                        <img src={process.env.REACT_APP_ABOUTUSIMAGEADSGRAMTAILOREDSOLUTIONS}/>
                    </div>
                </div>
                <div className='col-12 col-lg-6 col-xl-6 d-flex align-items-center'>
                <div data-aos="fade-left" className='container px-4'>
                    <h3  className='pb-2'>Tailored Solutions for Every Client:</h3>
                    <p>
                    At AdsGram, we understand that every client has unique needs and goals. That's why we offer personalized advertising solutions designed to meet the diverse requirements of businesses across industries. Whether it's increasing brand visibility, driving sales, promoting events, or raising awareness, our team of experts works closely with clients to develop customized ad campaigns that deliver measurable results and exceed expectations.
                    </p>               
                    </div>
                </div>
            </div>


            <div className='inodayamadsgramReverse d-flex flex-wrap'>
                <div className='inodayamadsgram col-12 col-lg-6 col-xl-6 border-end border-black d-flex align-items-center'>
                <div data-aos="fade-right" className='container px-4'>
                    <h3  className='pb-2'>Cutting-Edge Technology:</h3>
                    <p>
                    AdsGram leverages cutting-edge technology to deliver immersive and engaging advertising experiences across a variety of platforms. From high-resolution LED displays to interactive social media content and innovative holographic projections, we harness the latest advancements in digital advertising to captivate audiences and leave a lasting impression. With AdsGram, advertisers can stay ahead of the curve and harness the power of technology to elevate their brand and drive success.
                    </p>               
                    </div>
                </div>
                <div className='col-12 col-lg-6 col-xl-6 py-4'>
                <div data-aos="fade-left" className='container px-4'>
                        <img src={process.env.REACT_APP_ABOUTUSIMAGEADSGRAMCUTTINGEDGE}/>
                    </div>
                </div>
            </div>


            <div className='inodayamadsgramReverse d-flex flex-wrap'>
                <div className='inodayamadsgram col-12 col-lg-6 col-xl-6 border-end border-black py-4'>
                <div data-aos="fade-right" className='container px-4'>
                        <img src={process.env.REACT_APP_ABOUTUSIMAGEADSGRAMMEASURABLERESULTS}/>
                    </div>
                </div>
                <div className='col-12 col-lg-6 col-xl-6 d-flex align-items-center'>
                <div data-aos="fade-left" className='container px-4'>
                    <h3  className='pb-2'>Measurable Results and Analytics:</h3>
                    <p>
                    In addition to providing unmatched reach and engagement, AdsGram offers comprehensive analytics and reporting tools to track the performance of ad campaigns in real-time. Whether it's monitoring impressions, click-through rates, or audience demographics, our advanced analytics platform provides valuable insights that enable advertisers to optimize their campaigns for maximum effectiveness and ROI.
                    </p>               
                    </div>
                </div>
            </div>


            <div className='inodayamadsgramReverse d-flex flex-wrap'>
                <div className='inodayamadsgram col-12 col-lg-6 col-xl-6 border-end border-black d-flex align-items-center'>
                    <div data-aos="fade-right" className='container px-4'>
                    <h3  className='pb-2'>Join the AdsGram Revolution:</h3>
                    <p>
                    Join the ranks of businesses worldwide who have embraced the future of advertising with AdsGram. Whether you're looking to elevate your brand, drive sales, or engage audiences in new and innovative ways, AdsGram has the solutions and expertise to help you achieve your goals. Experience the power of dynamic advertising with AdsGram and unlock the potential of your brand today.
                    </p>               
                    </div>
                    </div>
                <div className='col-12 col-lg-6 col-xl-6 py-4'>
                <div data-aos="fade-left" className='container px-4'>
                        <img src={process.env.REACT_APP_ABOUTUSIMAGEADSGRAMJOINTHEADSGRAM}/>
                    </div>
                </div>
            </div>

        </div>
    </div>
  )
}
