import React from 'react'
import { BsArrowRight } from "react-icons/bs";
import { IoLocationOutline } from "react-icons/io5";
import { MdEmail } from "react-icons/md";
import { BiPhoneCall } from "react-icons/bi";
import { BiLogoFacebook } from "react-icons/bi";
import { FaXTwitter } from "react-icons/fa6";
import { AiFillSkype } from "react-icons/ai";
import { FaTelegram } from "react-icons/fa";

export default function Footer() {
  return (
   
<div className="footer_area footer_five" id="footer_contents">
  <div className="footer_widgets_wrap bg_dark_2">
    {/*===============spacing==============*/}
    <div className="pd_top_75" />
    {/*===============spacing==============*/}
    <div className="auto-container">
      <div className="row">
        <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
          <div className="footer_widgets wid_tit style_two">
            <div className="fo_wid_title">
              <h2>Our Works</h2>
            </div>
          </div>
       
          <div className="theme_btn_all color_one">
            <a href="#" target="_blank" rel="nofollow" className="theme-btn five">View All Works<BsArrowRight className="fs-6 mb-1 mx-1" /></a>
          </div>
          {/*===============spacing==============*/}
          <div className="pd_bottom_40" />
          {/*===============spacing==============*/}
        </div>
        <div className="col-xl-2 col-lg-6 col-md-6 col-sm-12">
          <div className="footer_widgets wid_tit style_two">
            <div className="fo_wid_title">
              <h2>Informations</h2>
            </div>
          </div>
          <div className="footer_widgets clearfix navigation_foo light_color style_one">
            <div className="navigation_foo_box">
              <div className="navigation_foo_inner">
                <ul className="menu">
                  <li className="menu-item"><a href="#">Services</a></li>
                  <li className="menu-item"><a href="#">Infrastructure</a></li>
                  <li className="menu-item"><a href="#">Client Support</a></li>
                  <li className="menu-item"><a href="#">Privacy policy</a></li>
                  <li className="menu-item"><a href="#">Terms of use</a></li>
                  <li className="menu-item"><a href="#">Sitemap</a></li>
                  <li className="menu-item"><a href="#">Careers</a></li>
                </ul>
              </div>
            </div>
          </div>
          {/*===============spacing==============*/}
          <div className="pd_bottom_40" />
          {/*===============spacing==============*/}
        </div>
        <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
          <div className="footer_widgets wid_tit style_two">
            <div className="fo_wid_title">
              <h2>Get In Touch</h2>
            </div>
          </div>
          <div className="footer_contact_list light_color type_one">
            <div className="same_contact address">
              <span className="icon-location2"><IoLocationOutline/></span>
              <div className="content">
                <h6 className="titles">Address</h6>
                <p>280 Granite Run Drive Suite #200 Lancaster</p>
              </div>
            </div>
          </div>
          <div className="footer_contact_list light_color type_one">
            <div className="same_contact mail">
              <span className="icon-mail"><MdEmail/></span>
              <div className="content">
                <h6 className="titles">Mail Us</h6>
                <a href="mailto:sendmail@creote.com">sendmail@creote.com</a>
              </div>
            </div>
          </div>
          {/*===============spacing==============*/}
          <div className="pd_bottom_20" />
          {/*===============spacing==============*/}
          <div className="footer_contact_list light_color type_one">
            <div className="same_contact phone">
              <span className="icon-telephone"><BiPhoneCall/></span>
              <div className="content">
                <h6 className="titles"> Phone</h6>
                <a href="tel:+9806071234 ">+9806071234 </a>
              </div>
            </div>
          </div>
          {/*===============spacing==============*/}
          <div className="pd_bottom_40" />
          {/*===============spacing==============*/}
        </div>
        <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
          <div className="footer_widgets wid_tit style_two">
            <div className="fo_wid_title">
              <h2>Subscribe Us Today</h2>
            </div>
          </div>
          <div className="footer_widgets foo_subscribe light_color style_one">
            <div className="item_subscribe with_text">
              <p>Subscribe Us &amp; Recive Our Offers and Updates i Your Inbox Directly.</p>
              <div className="shortcodes">
                <form className="mc4wp-form" method="post" data-name="Subscibe">
                  <div className="mc4wp-form-fields">
                    <input type="email" name="EMAIL" placeholder="Your email address" required />
                    <input type="submit" defaultValue="Sign up" />
                  </div>
                </form>
              </div>
              <p>* We do not share your email id</p>
            </div>
          </div>
          <div className="social_media_v_one light">
            <ul>
              <li>
                <a href="#"> <span className="fa fa-facebook" ><BiLogoFacebook className='fs-5'/></span>
                  <small>facebook</small>
                </a>
              </li>
              <li>
                <a href="#"> <span className="fa fa-twitter" ><FaXTwitter className='fs-5'/></span>
                  <small>twitter</small>
                </a>
              </li>
              <li>
                <a href="#"> <span className="fa fa-skype"><AiFillSkype className='fs-5'/></span>
                  <small>skype</small>
                </a>
              </li>
              <li>
                <a href="#"> <span className="fa fa-instagram" ><FaTelegram className='fs-5'/></span>
                  <small>instagram</small>
                </a>
              </li>
            </ul>
          </div>
          {/*===============spacing==============*/}
          <div className="pd_bottom_40" />
          {/*===============spacing==============*/}
        </div>
      </div>
    </div>
    {/*===============spacing==============*/}
    <div className="pd_bottom_35" />
    {/*===============spacing==============*/}
  </div>
  <div className="footer-copyright  bg_dark_1">
    {/*===============spacing==============*/}
    <div className="pd_top_15" />
    {/*===============spacing==============*/}
    <div className="auto-container">
      <div className="footer_copy_right">
        <div className="row ">
          <div className="col-lg-6 col-md-6 col-sm-12">
            <ul className="list_items_new_box type_one  display_inline">
              <li>
                <a href="#" className="color_white">
                  <span className="fa fa-check-circle icon color_white" />
                  Terms of use        
                </a>
              </li>
              <li>
                <a href="#" className="color_white">
                  <span className="fa fa-check-circle icon color_white" />
                  Sitemap        
                </a>
              </li>
              <li>
                <a href="#" className="color_white">
                  <span className="fa fa-check-circle icon color_white" />
                  Careers        
                </a>
              </li>
            </ul>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 text-md-end">
            <div className="footer_copy_content color_white">© 2023 <a href="https://themeforest.net/user/steelthemes" className="color_white">Steelthemes.</a> All Rights Reserved.</div>
          </div>
        </div>
      </div>
    </div>
    {/*===============spacing==============*/}
    <div className="pd_bottom_5" />
    {/*===============spacing==============*/}
  </div>
</div>

    
  )
}
